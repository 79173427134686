import { toast } from "react-hot-toast";
import { RemoveFromWatchList } from "../apiCall";

export async function RemoveFromFavorites(productId, dispatch, navigateTo=undefined) {
  const isLogin = localStorage.getItem("token") ? true : false;

  if (isLogin) {
    const re = await RemoveFromWatchList(productId);
    if (re?.status === 200) {
      dispatch({ type: "REMOVE_FROM_LIKED_PRODUCT", productId: productId });
    } else {
      toast.error(re?.response?.data?.status[0]?.ResponseMessage);
    }
  } else if(navigateTo) {
    navigateTo('/login')
  }
}
