import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logos } from "../../Assets/icons/logo-s.svg";
import { ReactComponent as FaceBookIcon } from "../../Assets/icons/facebook.svg";
import { ReactComponent as InstaIcon } from "../../Assets/icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../Assets/icons/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../Assets/icons/linkedin.svg";
import { ReactComponent as MasterCardIcon } from "../../Assets/icons/master-card.svg";
import { ReactComponent as PayPalIcon } from "../../Assets/icons/pay-pal.svg";
import { ReactComponent as EbayIcon } from "../../Assets/icons/e-bay.svg";
import { ReactComponent as GpayIcon } from "../../Assets/icons/google-pay.svg";
import { ReactComponent as MonoBankIcon } from "../../Assets/icons/monobank.svg";
import { ReactComponent as DiscoverIcon } from "../../Assets/icons/discover.svg";
import { ReactComponent as ApplePayIcon } from "../../Assets/icons/apple-pay.svg";
import BlackLogos from "../../Assets/icons/logos-black.png";
import "./footer.scss";
import { addToEmailList } from "../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../StateProvider";

const Footer = () => {
    const navigateTo = useNavigate()
    const [email, setEmail] = useState()
    const [, dispatch] = useStateValue();

    const submitForm = async () => {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const Re = await addToEmailList(email);
        dispatch({ type: "SET_IS_LOADING", status: false });
        if (Re.data?.status[0].Error === "False") {
            toast.success(
                "We have received your query and will get back to you soon."
            );
            setEmail("")
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    return (
        <>
            <div className='footer-main'>
                <h3>Let's Talk!</h3>
                <div className='container'>
                    <Logos />
                    <span>
                        <p>INFO@TWOCARS.COM</p>
                        <p>(078) 12345 12112</p>
                    </span>
                    <span>
                        <p>6 RUE MAURICE BELLMAN</p>
                        <p>690005 LYON</p>
                        <span className='country'>FRANCE</span>
                    </span>
                </div>
                <hr />
                <div className='container'>
                    <p>Copyright@2023. All Rights Reserved</p>
                    <div className='manues'>
                        <span onClick={() => navigateTo('/shipping-policy')}>Shipping Policy</span>
                        <span onClick={() => navigateTo('/privacy-policy')}>Privacy Policy</span>
                        <span onClick={() => navigateTo('/faqs')}>FAQ</span>
                        <span onClick={() => navigateTo('/contact-us')}>Contact Us</span>
                    </div>
                </div>
            </div>
            <div className='footer-main-mobile'>
                <div className='subscribe'>
                    <div className='details'>
                        <h4>Sign up style news</h4>
                        <p>
                            Be the first to know about our latest updates,
                            exclusive offers, and more.
                        </p>
                    </div>
                    <div className='input-container'>
                        <input type='email' placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <button className='primary-btn-black' onClick={submitForm}>SUBSCRIBE</button>
                    </div>
                    <div className="logos">
                      <img src={BlackLogos} alt="" />
                      <div className="socials">
                        <FaceBookIcon />
                        <InstaIcon />
                        <TwitterIcon />
                        <LinkedInIcon />
                      </div>
                    </div>
                </div>
                <hr />
                <div className="my-links">
                    <div className="container">
                        <span className="bold">Company Info</span>
                        <span>About</span>
                        <span>Social Responsibility</span>
                        <span>Affilate</span>
                        <span>Fashion Blogger</span>
                    </div>
                    <div className="container">
                        <span className="bold">Help & Support</span>
                        <span onClick={() => navigateTo('/shipping-policy')}>Shipping info</span>
                        <span>Returns</span>
                        <span>How to order</span>
                        <span>How to track</span>
                        <span>Size Charts</span>
                    </div>
                    <div className="container">
                        <span className="bold">Customer Care</span>
                        <span onClick={() => navigateTo('/contact-us')}>Contact Us</span>
                        <span>Payment</span>
                        <span>Bonus Point</span>
                        <span>Notices</span>
                    </div>
                </div>
                <hr />
                <div className="we-accept">
                    <p>WE ACCEPT</p>
                    <div className="logos">
                        <MasterCardIcon />
                        <PayPalIcon />
                        <EbayIcon />
                        <GpayIcon />
                        <MonoBankIcon />
                        <DiscoverIcon />
                        <ApplePayIcon />
                        <MasterCardIcon />
                        <PayPalIcon />
                        <EbayIcon />
                        <GpayIcon />
                        <MonoBankIcon />
                        <DiscoverIcon />
                        <ApplePayIcon />
                    </div>
                </div>
                <hr />
                <div className="policies">
                    <p>Privacy Center</p>
                    <span></span>
                    <p onClick={() => navigateTo('/privacy-policy')}>Privacy & Cookie Policy</p>
                    <span></span>
                    <p>Manage Cookies</p>
                    <span></span>
                    <p>Terms & Conditions</p>
                    <span></span>
                    <p>Copyright Notice</p>
                    <span></span>
                    <p onClick={() => navigateTo('/faqs')}>Faqs</p>
                </div>
                <p className="copyrights">© 2024 UI. All Rights Reserved.</p>
            </div>
        </>
    );
};

export default Footer;
