import { api } from "./Constants";
import { request } from "./utils/axios-utils";

// ***************************************************  Create User  ****************************************************************
export async function SignUp(data) {
  return request({ url: api.signup, method: "post", data: data });
}
export const googleLogin = (googleToken) => {
  return request({
    url: api.google_login,
    method: "post",
    data: {
      access_token: googleToken,
    },
  });
};
// ***************************************************  get products  ****************************************************************
export async function GetProducts(categoryFilter, sizeFliter) {
  return request({
    url: `${api.products}?categoryId=${categoryFilter}&sizeId=${sizeFliter}`,
    method: "get",
  });
}

// ***************************************************  get product details  ****************************************************************
export async function GetProductDetails(id) {
  return request({ url: api.product_details + "/" + id, method: "get" });
}

// ***************************************************  forgot password  ****************************************************************
export async function ResetPassword(data) {
  return request({ url: api.reset_password, method: "post", data: data });
}

// ***************************************************  get client details ****************************************************************
export async function SendOtp(data) {
  return request({ url: api.otp, method: "post", data: data });
}

// ***************************************************  get client details ****************************************************************
export async function VerifyOtp(otp) {
  return request({ url: api.verify_email_otp + "/" + otp, method: "post"});
}
// ***************************************************  create support ticket ****************************************************************
export async function SubmitContactRequest(data) {
  return request({ url: api.contact, method: "post", data: data });
}
// ***************************************************  login  ****************************************************************
export async function login(data) {
  return request({ url: api.login, method: "post", data: data });
}
// ***************************************************  get product filter  ****************************************************************
export async function GetProductFilter() {
  return request({ url: api.productFilter, method: "get" });
}
// ***************************************************  get popular product   ****************************************************************
export async function GetPopularProducts() {
  return request({ url: api.popularProducts, method: "get" });
}
// ***************************************************  add to cart   ****************************************************************
export async function AddToCart(data) {
  return request({ url: api.addToCart, method: "post", data: data });
}
// ***************************************************  update  cart   ****************************************************************
export async function UpdateCart(data) {
  return request({ url: api.updateCart, method: "patch", data: data });
}
// ***************************************************  remove from cart   ****************************************************************
export async function RemoveFromCart(productId, sizeId) {
  return request({
    url: `${api.removeFromCart}/${productId}/${sizeId}`,
    method: "delete",
  });
}
// ***************************************************  remove from cart   ****************************************************************
export async function GetCart() {
  return request({ url: `${api.getCart}`, method: "get" });
}
// ***************************************************  add address   ****************************************************************
export async function AddAddress(data) {
  return request({ url: api.addAddress, method: "post", data: data });
}
// ***************************************************  update  caaddressrt   ****************************************************************
export async function UpdateAddress(data) {
  return request({ url: api.updateAddress, method: "patch", data: data });
}
// ***************************************************  remove address   ****************************************************************
export async function RemoveAddress(addressId) {
  return request({
    url: `${api.removeAddress}/${addressId}`,
    method: "delete",
  });
}
// ***************************************************  get address   ****************************************************************
export async function GetAddress() {
  return request({ url: `${api.getAddress}`, method: "get" });
}
// ***************************************************  get coupon codes   ****************************************************************
export async function GetCouponCodes() {
  return request({ url: `${api.getCouponCodes}`, method: "get" });
}
// ***************************************************  get cart total  ****************************************************************
export async function GetCartTotal(isCoinUse = false, couponCode = "", donate=true) {
  return request({
    url: `${api.getCartTotal}?is_coin_use=${isCoinUse}&coupon_code=${couponCode}&donate=${donate}`,
    method: "get",
  });
}
// ***************************************************  create order  ****************************************************************
export async function CreateOrder(data) {
  return request({ url: `${api.createOrder}`, method: "post", data: data });
}
// ***************************************************  create order  ****************************************************************
export async function CreatePaymentOrder(data) {
  return request({ url: `${api.createPaymentOrder}`, method: "post", data });
}
// ***************************************************  get order  ****************************************************************
export async function GetOrder() {
  return request({ url: `${api.getorder}`, method: "get" });
}
// ***************************************************  get order details ****************************************************************
export async function GetOrderDetails(id) {
  return request({ url: `${api.getorder}/${id}`, method: "get" });
}
// *************************************************** return order ****************************************************************
export async function ReturnOrder(data) {
  return request({ url: `${api.returnorder}`, method: "post", data: data });
}
// *************************************************** cancel order ****************************************************************
export async function CancelOrderById(data) {
  return request({ url: `${api.cancelorder}`, method: "post", data: data });
}
// ***************************************************  get terms of use ****************************************************************
export async function GetPrivacyPolicy() {
  return request({ url: api.privacy, method: "get" });
}
// ***************************************************  get terms of use ****************************************************************
export async function GetHomeContent() {
  return request({ url: api.home_content, method: "get" });
}
// ***************************************************  get terms of use ****************************************************************
export async function GetShippingPolicy() {
  return request({ url: api.shipping, method: "get" });
}
// ***************************************************  get faqs  ****************************************************************
export async function getFaqs() {
  return request({ url: api.faqs, method: "get" });
}
// ***************************************************  send otp  ****************************************************************
export async function sendOtp(data) {
  return request({ url: api.send_otp, method: "post", data: data });
}
// ***************************************************  verify otp  ****************************************************************
export async function verifyOtp(data) {
  return request({ url: api.verify_otp, method: "post", data: data });
}
// ***************************************************  Complete profile  ****************************************************************
export async function completeProfile(data) {
  return request({ url: api.complete_profile, method: "post", data: data });
}
// ***************************************************  update profile  ****************************************************************
export async function updateProfile(data) {
  return request({ url: api.update_profile, method: "patch", data: data });
}

// ***************************************************  get watch list ****************************************************************

export async function GetWatchList() {
  return request({ url: `${api.getWatchList}` });
}
// ***************************************************  delete from watch list ****************************************************************

export async function RemoveFromWatchList(productId) {
  return request({
    url: `${api.removeFromWatchList}/${productId}`,
    method: "delete",
  });
}
// ***************************************************  add to watch list ****************************************************************

export async function AddToWatchList(productId) {
  return request({
    url: `${api.addToWatchList}/${productId}`,
    method: "post",
  });
}
// *************************************************** generate refresh token ****************************************************************
export async function GenerateRefreshToken() {
  return request({ url: api.generate_refresh_token, method: "get" });
}
// *************************************************** add to email list ****************************************************************

export async function addToEmailList(email) {
  return request({ url: api.add_to_email_list + "?email="+email, method: "post" });
}