// export const domainName = "http://localhost:8000";
export const domainName = "https://admin.pragati.aapkacfo.com";

export const googleClinetId = "1033106969051-fak77bk40o0r5vmipov8v0qcdb75r2a0.apps.googleusercontent.com";

export const STALE_TIME = 0; // Data is considered fresh (in milliseconds)
export const CACHE_TIME = 7200000; // Data will be cached for 2 hour (in milliseconds)
export const api = {
  signup: "/api/register",
  google_login: "/api/verify/google_access_token",
  products: "/api/get-filter-products",
  popularProducts: "/api/get-popular-products",
  product_details: "/api/get-product-info",
  login: "/api/login",
  contact: "/api/contact-us",
  productFilter: "/api/get-product-filter",
  addToCart: "/api/add-to-cart",
  updateCart: "/api/update-quantity",
  removeFromCart: "/api/delete-from-cart",
  getCart: "/api/get-cart",
  addAddress: "/api/add-address",
  updateAddress: "/api/update-address",
  removeAddress: "/api/delete-address",
  getAddress: "/api/get-addresses",
  getCouponCodes: "/api/get-coupon-codes",
  getCartTotal: "/api/get-cart-total",
  createOrder: "/api/create-order",
  getorder: "/api/get-orders",
  returnorder: "/api/return-order",
  cancelorder: "/api/cancel-order",
  createPaymentOrder: "/api/payment",
  privacy: "/api/privacy-policy",
  home_content: "/api/home-content",
  shipping: "/api/shipping-policy",
  faqs: "/api/faqs",
  send_otp: "/api/send-otp",
  verify_otp: "/api/verify-otp",
  complete_profile: "/api/complete-profile",
  update_profile: "/api/update-profile",
  getWatchList: "/api/get-favourite-list",
  removeFromWatchList: "/api/remove-from-favourite-list",
  addToWatchList: "/api/add-favourite-list",
  generate_refresh_token: "/api/refresh",
  add_to_email_list: "/api/add-to-email-list",
  otp: "/api/otp",
  verify_email_otp: "/api/verify-email-otp",
  reset_password: "/api/resetPassword",
};
