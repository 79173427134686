import React, { useEffect, useState } from "react";

import Img4 from "../../Assets/images/img4.svg";
import Img10 from "../../Assets/images/img10.svg";
import Img11 from "../../Assets/images/img11.svg";
import "./products.scss";
import { useNavigate, useParams } from "react-router-dom";
import { GetProductFilter, GetProducts } from "../../apiCall";
import toast from "react-hot-toast";
import { domainName } from "../../Constants";
import { useQuery } from "react-query";
import { useStateValue } from "../../StateProvider";

const Products = () => {
    const { category_id } = useParams();
    const [hoverItem, setHoverItem] = useState();
    const [, dispatch] = useStateValue();
    const navigateTo = useNavigate();
    const [appliedSizeFilters, setAppliedSizeFilters] = useState("");
    const [appliedCollectionFilters, setAppliedCollectionFilters] = useState(
        parseInt(category_id) ? parseInt(category_id) : ""
    );
    const [products, setProducts] = useState([]);
    const [productsImages, setProductsImages] = useState([]);
    const [productFilter, setProductFilter] = useState([]);

    const { isLoading: isProductLoading } = useQuery({
        queryKey: ["proucts", appliedSizeFilters, appliedCollectionFilters],
        queryFn: async () => {
            dispatch({ type: "SET_LOADING", status: true });
            const Re = await GetProducts(
                appliedCollectionFilters,
                appliedSizeFilters
            );
            dispatch({ type: "SET_LOADING", status: false });

            if (Re?.status === 200) {
                setProducts(Re.data.value);
                setProductsImages(Re.data.value);
            } else if (Re?.response?.status === 401) {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.removeItem("token");
                localStorage.removeItem("user_details");
                localStorage.removeItem("cartItem");
                navigateTo("/login");
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data?.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try agian.");
                }
            }
        },
    });
    const { isLoading: isProductFliterLoading, data: filterList } = useQuery({
        queryKey: ["proucts-filter"],
        queryFn: async () => {
            dispatch({ type: "SET_LOADING", status: true });
            const Re = await GetProductFilter();
            dispatch({ type: "SET_LOADING", status: false });

            if (Re?.status === 200) {
                setProductFilter(Re.data.value);
            } else if (Re?.response?.status === 401) {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.removeItem("token");
                localStorage.removeItem("user_details");
                localStorage.removeItem("cartItem");
                navigateTo("/login");
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data?.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try agian.");
                }
            }
        },
    });
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    useEffect(() => {
        products?.forEach((prod) => {
            if (prod?.main_image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + "/uploads/" + prod?.main_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, main_image: url, loaded: true });

                    setProductsImages((pre) => {
                        return pre?.map((myProd) => {
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    main_image: url,
                                    loaded_main: true,
                                };
                            } else {
                                return myProd;
                            }
                        });
                    });
                };
                xhr.send();
            }
            if (prod?.hover_image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + "/uploads/" + prod?.hover_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, hover_image: url, loaded: true });

                    setProductsImages((pre) => {
                        return pre?.map((myProd) => {
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    hover_image: url,
                                    loaded_hover: true,
                                };
                            } else {
                                return myProd;
                            }
                        });
                    });
                };
                xhr.send();
            }
        });
    }, [products]);

    return (
        <div className='products-main'>
            <div className='head'>
                <h2>SHOP ALL</h2>
                <p>{products?.length} Products</p>
            </div>
            <div className='filters'>
                <p>Filter: </p>
                {productFilter.map((filter) => (
                    <select
                        name='size'
                        id={filter?.title.toUpperCase()}
                        onChange={(e) => {
                            if (filter?.title === "Size") {
                                setAppliedSizeFilters(parseInt(e.target.value));
                            } else {
                                setAppliedCollectionFilters(
                                    parseInt(e.target.value)
                                );
                            }
                        }}>
                        <option disabled selected>
                            {filter?.title.toUpperCase()}
                        </option>
                        {filter.list.map((item) => (
                            <option value={item?.id}>
                                {item?.title.toUpperCase()}
                            </option>
                        ))}
                    </select>
                ))}
            </div>
            {productsImages?.length > 0 ? (
                <div className='products'>
                    {productsImages?.map((product) => (
                        <div
                            className='product'
                            key={"product-" + product?.id}
                            onMouseEnter={() => setHoverItem(product.id)}
                            onMouseLeave={() => setHoverItem()}
                            onClick={() =>
                                navigateTo("/product-details/" + product.id)
                            }>
                            {hoverItem === product?.id ? (
                                (product?.loaded_hover && product?.loaded_main) ? (
                                    <img src={product?.hover_image} alt='' />
                                ) : (
                                    <div className='loading-statistics-container'>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                    </div>
                                )
                            ) : (product?.loaded_hover && product?.loaded_main) ? (
                                <img src={product?.main_image} alt='' />
                            ) : (
                                <div className='loading-statistics-container'>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                </div>
                            )}
                            <div className='content'>
                                <div className='line1'>
                                    <p className='title'>{product?.title}</p>
                                    {/* <p className='tag'>{product?.tag}</p> */}
                                </div>
                                <p className='bold'>₹{product?.price}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <h4 className='not-found'>No products are available.</h4>
            )}
            <div className='filters-mobile'>
                {productFilter.map((filter) => (
                    <select
                        name='size'
                        id={filter?.title.toUpperCase()}
                        onChange={(e) => {
                            if (filter?.title === "Size") {
                                setAppliedSizeFilters(parseInt(e.target.value));
                            } else {
                                setAppliedCollectionFilters(
                                    parseInt(e.target.value)
                                );
                            }
                        }}>
                        <option disabled selected>
                            {filter?.title.toUpperCase()}
                        </option>
                        {filter.list.map((item) => (
                            <option value={item?.id}>
                                {item?.title.toUpperCase()}
                            </option>
                        ))}
                    </select>
                ))}
            </div>
        </div>
    );
};

export default Products;
