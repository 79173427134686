import React, { useEffect, useState } from "react";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";
import Collapse from "react-bootstrap/Collapse";

import { ReactComponent as UpArrowIcon } from "../../Assets/icons/up-arrow.svg";
import "./faqs.scss";
import { getFaqs } from "../../apiCall";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { CACHE_TIME } from "../../Constants";

const Faqs = () => {
    const [currentCollaps, setCurrentCollaps] = useState(0);
    const handleCollapsChange = (num) => {
        if (currentCollaps === num) {
            setCurrentCollaps(0);
        } else {
            setCurrentCollaps(num);
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    const [faqs, setFaqs] = useState([]);

    useQuery(["get-faqs"], () => getFaqs(), {
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data?.data?.status[0].Error === "False") {
                setFaqs(data.data.value?.details);
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    return (
        <div className='faqs-main'>
            <h3>FAQ</h3>
            <div className='faqs'>
                {faqs?.map((faq, indx) => <div className='fc-light fs-p' key={"faq-"+indx}>
                    <p
                        className='each-faq'
                        onClick={() => handleCollapsChange(1)}>
                        {faq?.title}
                        <span
                            className='plus-faq'
                            aria-controls='example-collapse-text1'
                            aria-expanded={currentCollaps === indx}>
                            {currentCollaps === indx ? (
                                <UpArrowIcon className='open' />
                            ) : (
                                <UpArrowIcon className='close' />
                            )}
                        </span>
                    </p>
                    <Collapse in={currentCollaps === indx}>
                        <div
                            className='each-faq-ans'
                            id='example-collapse-text1'>
                            {faq?.description}
                        </div>
                    </Collapse>
                </div>)}
            </div>
        </div>
    );
};

export default Faqs;
