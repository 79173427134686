import React, { useEffect } from "react";

import { ReactComponent as CloseIcon } from "../../Assets/icons/close.svg";
import "./cartModel.scss";
import { useStateValue } from "../../StateProvider";
import { useNavigate } from "react-router-dom";

import {
    GenerateRefreshToken,
    RemoveFromCart,
    UpdateCart,
} from "../../apiCall";
import toast from "react-hot-toast";

const CartModel = () => {
    const [{ cartItem }, dispatch] = useStateValue();
    const navigateTo = useNavigate();

    const hideCartModel = () => {
        dispatch({
            type: "SET_CART_SHOW",
            status: false,
        });
        if (typeof window != "undefined" && window.document) {
            document.body.style.overflow = "unset";
        }
    };
    const decreaseQuantity = async (item) => {
        if (item.quantity > 1) {
            dispatch({ type: "SET_IS_LOADING", status: true });
            const re = await UpdateCart({
                product_id: item.id,
                size_id: item.size.id,
                quantity: item.quantity - 1,
            });
            dispatch({ type: "SET_IS_LOADING", status: false });
            console.log(re);
            if (re.status === 401) {
                dispatch({ type: "SET_IS_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_IS_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    decreaseQuantity(item);
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (re.status === 200) {
                dispatch({
                    type: "UPDATE_CART",
                    cartData: { ...item, quantity: item.quantity - 1 },
                });
                //   toast.success(re.data.status[0].ResponseMessage);
            } else {
                toast.error(re?.resaponse?.data?.status[0]?.ResponseMessage);
            }
        } else {
            const isConfirmed = window.confirm(
                "Are you sure you want to remove product from cart?"
            );

            if (isConfirmed) {
                dispatch({ type: "SET_IS_LOADING", status: true });
                const re = await RemoveFromCart(item.id, item.size.id);
                dispatch({ type: "SET_IS_LOADING", status: false });
                console.log(re);
                if (re.status === 401) {
                    dispatch({ type: "SET_IS_LOADING", status: true });
                    const refreshRe = await GenerateRefreshToken();
                    dispatch({ type: "SET_IS_LOADING", status: false });

                    console.log(refreshRe, "REFRESH RE");

                    if (refreshRe?.data?.access_token) {
                        localStorage.setItem(
                            "refresh_token",
                            refreshRe?.data?.refresh_token
                        );
                        localStorage.setItem(
                            "token",
                            refreshRe?.data?.access_token
                        );
                        decreaseQuantity(item);
                    } else {
                        dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                        dispatch({ type: "SET_CART_ITEM", status: [] });
                        dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                        dispatch({ type: "SET_USER_DETAIL", detail: {} });
                        localStorage.clear();
                        sessionStorage.clear();
                        navigateTo("/login");
                    }
                } else if (re?.status === 200) {
                    dispatch({
                        type: "REMOVE_FROM_CART",
                        cartData: item,
                    });
                    //   toast.success(re.data.status[0].ResponseMessage);
                } else if (re?.response?.status === 401) {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.removeItem("token");
                    localStorage.removeItem("user_details");
                    localStorage.removeItem("cartItem");
                    navigateTo("/login");
                } else {
                    toast.error(
                        re?.resaponse?.data?.status[0]?.ResponseMessage
                    );
                }
            }
        }
    };
    const increaseQuantity = async (item) => {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await UpdateCart({
            product_id: item.id,
            size_id: item.size.id,
            quantity: item.quantity + 1,
        });
        dispatch({ type: "SET_IS_LOADING", status: false });
        console.log(re);
        if (re.status === 401) {
            dispatch({ type: "SET_IS_LOADING", status: true });
            const refreshRe = await GenerateRefreshToken();
            dispatch({ type: "SET_IS_LOADING", status: false });

            console.log(refreshRe, "REFRESH RE");

            if (refreshRe?.data?.access_token) {
                localStorage.setItem(
                    "refresh_token",
                    refreshRe?.data?.refresh_token
                );
                localStorage.setItem("token", refreshRe?.data?.access_token);
                increaseQuantity(item);
            } else {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.clear();
                sessionStorage.clear();
                navigateTo("/login");
            }
        } else if (re.status === 200) {
            dispatch({
                type: "UPDATE_CART",
                cartData: { ...item, quantity: item.quantity + 1 },
            });
            //   toast.success(re.data.status[0].ResponseMessage);
        } else {
            toast.error(re.resaponse.data.status[0].ResponseMessage);
        }
    };
    return (
        <>
            <div className='backdrop' onClick={() => hideCartModel()}></div>
            <div className='cart-model-main'>
                <div className='head'>
                    <p>CART</p>
                    <CloseIcon onClick={() => hideCartModel()} />
                </div>
                <p className='number-of-items'>{cartItem?.length} Items</p>
                {cartItem?.length > 0 ? (
                    <div className='cart-items'>
                        {cartItem?.map((item) => (
                            <div
                                className='cart-item'
                                key={"cart-item-" + item?.id}>
                                    {console.log(item?.img, "STARTING")}
                                {item?.img?.includes("blob:") ? (
                                    <img src={`${item?.img}`} alt='' />
                                ) : (
                                    <div className='loading-statistics-container hover-image'>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                    </div>
                                )}
                                <div className='details'>
                                    <p className='name'>{item?.name}</p>
                                    <p className='size'>
                                        Size: <span>{item?.size?.title}</span>
                                    </p>
                                </div>
                                <div className='sign-price'>
                                    <div className='quantity'>
                                        <span
                                            className='sign'
                                            onClick={() =>
                                                decreaseQuantity(item)
                                            }>
                                            -
                                        </span>
                                        <span className='number'>
                                            {item?.quantity}
                                        </span>
                                        <span
                                            className='sign'
                                            onClick={() =>
                                                increaseQuantity(item)
                                            }>
                                            +
                                        </span>
                                    </div>
                                    <p className='price'>
                                        {(
                                            item.price - item.discount
                                        ).toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "INR",
                                        })}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        <p className='empty'>No items found</p>
                        <button
                            className='primary-btn-black'
                            onClick={() => {
                                hideCartModel();
                                navigateTo("/products");
                            }}>
                            CONTINUE SHOPPING
                        </button>
                    </>
                )}
                {cartItem?.length > 0 && (
                    <button
                        className='primary-btn-black'
                        onClick={() => {
                            hideCartModel();
                            navigateTo("/checkout");
                        }}>
                        CHECKOUT
                    </button>
                )}
            </div>
        </>
    );
};

export default CartModel;
