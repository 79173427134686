import React, { useEffect, useState } from "react";

import "./privacyPolicy.scss";
import { GetPrivacyPolicy } from "../../apiCall";
import { useQuery } from "react-query";
import { CACHE_TIME } from "../../Constants";
import toast from "react-hot-toast";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [content, setContent] = useState();
    useQuery(["privacy-policy"], () => GetPrivacyPolicy(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // console.log(data);
            if (data.data?.status[0].Error === "False") {
                setContent(data.data.value);
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);
    return (
        <div className='privacy-policy-main'>
            <h3>PRIVACY POLICY</h3>
            {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
        </div>
    );
};

export default PrivacyPolicy;
