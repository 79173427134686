import React, { useEffect, useState } from "react";

import "./shippingPolicy.scss";
import { GetShippingPolicy } from "../../apiCall";
import { useQuery } from "react-query";
import { CACHE_TIME } from "../../Constants";
import toast from "react-hot-toast";

const ShippingPolicy = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [content, setContent] = useState();
    useQuery(["shipping-policy"], () => GetShippingPolicy(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // console.log(data);
            if (data.data?.status[0].Error === "False") {
                setContent(data.data.value);
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (
        <div className='shipping-policy-main'>
            <h3>SHIPPING POLICY</h3>
            {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
        </div>
    );
};

export default ShippingPolicy;
