import React, { useEffect } from "react";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

import { ReactComponent as CoinIcon } from "../../Assets/icons/coin.svg";
import { ReactComponent as CartIcon } from "../../Assets/icons/cart.svg";
import { ReactComponent as LikeIcon } from "../../Assets/icons/like.svg";
import { ReactComponent as UserIcon } from "../../Assets/icons/user.svg";
import { ReactComponent as MenuIcon } from "../../Assets/icons/menu.svg";
import { ReactComponent as Logo } from "../../Assets/icons/logo.svg";
import { ReactComponent as ExploreIcon } from "../../Assets/icons/explore.svg";
import { ReactComponent as SearchIcon } from "../../Assets/icons/search.svg";
import { ReactComponent as InfoIcon } from "../../Assets/icons/info.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/close.svg";
import "./nav.scss";
import { useNavigate } from "react-router-dom";
import CartModel from "../CartModel/CartModel";
import { useStateValue } from "../../StateProvider";
import SearchInput from "./component/searchInput"

const Nav = ({ is_login }) => {
    const navigateTo = useNavigate();
    const [{ showCart, isLoggedIn, likedProduct, cartItem }, dispatch] = useStateValue();
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false)
    const handleSearchOpen = () => setOpen(true);
  const handleSearchClose = () => setOpen(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const showCartModel = () => {
        dispatch({
            type: "SET_CART_SHOW",
            status: true,
        });
        if (typeof window != "undefined" && window.document) {
            document.body.style.overflow = "hidden";
        }
    };



    return (
        <>
            <nav className={is_login ? "nav-main login-nav" : "nav-main"}>
                {!is_login && (
                    <div className='part part-1'>
                        <span onClick={() => navigateTo("/products")}>
                            Explore
                        </span>
                        <span onClick={handleSearchOpen}>Search</span>
                        {open && <SearchInput handleSearchClose={handleSearchClose} handleClose={handleClose}/>}
                    </div>
                )}
                <div className='part part-2'>
                    <Logo onClick={() => navigateTo("/")} />
                </div>
                <div className='part part-3'>
                    {!is_login && isLoggedIn && (
                        <>
                            <span>
                                <p>{localStorage.getItem("coins") || sessionStorage.getItem("coins") || "0"}</p>
                                <CoinIcon />
                            </span>
                            <span className='show-on-mobile'>
                                <CartIcon
                                    className='normal-icon'
                                    onClick={() => showCartModel()}
                                />
                                <span className="number">{cartItem?.reduce((acc, curr) => acc + curr.quantity, 0)}</span>
                            </span>
                            <span>
                                <LikeIcon
                                    className='normal-icon'
                                    onClick={() => navigateTo("/wishlist")}
                                    />
                                    <span className="number">{likedProduct?.length}</span>
                            </span>
                            <span>
                                <UserIcon
                                    className='normal-icon'
                                    onClick={() => navigateTo("/profile")}
                                />
                            </span>
                        </>
                    )}
                    <span className='show-on-mobile disable-on-desktop'>
                        <MenuIcon
                            className='normal-icon'
                            onClick={handleShow}
                        />
                    </span>
                </div>
                {showCart && !is_login && <CartModel />}
                {!isLoggedIn && (
                    <div className='part part-1'>
                        <span onClick={() => navigateTo("/login")}>
                            Sign In
                        </span>
                        {/* <span onClick={() => navigateTo("/signup")}>
                            Sign Up
                        </span> */}
                    </div>
                )}
            </nav>
            <div className='nav-offcanvas'>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement='end'
                    className='nav-offcanvas'
                    name='end'>
                    <Offcanvas.Header>
                        {!is_login && isLoggedIn && (
                            <span className='coins'>
                                {localStorage.getItem("coins") || sessionStorage.getItem("coins") || "0"} <CoinIcon />
                            </span>
                        )}
                        <CloseIcon onClick={handleClose} />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <span onClick={() => {handleClose(); navigateTo("/products")}}>
                            <ExploreIcon /> <p>Explore</p>
                        </span>
                        <span>
                            <SearchIcon onClick={handleSearchOpen}/> <p onClick={handleSearchOpen}>Search</p>
                            {open && <SearchInput handleSearchClose={handleSearchClose} handleClose={handleClose}/>}
                        </span>
                        {!is_login && isLoggedIn && (
                            <span onClick={() => {handleClose(); navigateTo("/wishlist")}}>
                                <LikeIcon /> <p>Wishlist</p>
                            </span>
                        )}
                        {!is_login && isLoggedIn && (
                            <span onClick={() => {handleClose(); navigateTo("/profile")}}>
                                <UserIcon /> <p>User Settings</p>
                            </span>
                        )}
                        <span>
                            <InfoIcon /> <p>Help and Info</p>
                        </span>
                        {!isLoggedIn && (
                            <div className='buttons'>
                                <button
                                    className='primary-btn-white'
                                    onClick={() => {
                                        handleClose();
                                        navigateTo("/login");
                                    }}>
                                    Sign In
                                </button>
                                <button
                                    className='primary-btn-white'
                                    onClick={() => {
                                        handleClose();
                                        navigateTo("/signup");
                                    }}>
                                    Sign Up
                                </button>
                            </div>
                        )}
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
};

export default Nav;
