import React, { useState } from "react";

import { ReactComponent as CheckMark } from "../../../../Assets/icons/correct.svg";
import { ReactComponent as FacebookIcon } from "../../../../Assets/icons/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../../../Assets/icons/google.svg";
import image from "../../../../Assets/images/img10.svg";
import "./signUp.scss";
import { useForm } from "react-hook-form";
import { sendOtp, googleLogin } from "../../../../apiCall";
import toast from "react-hot-toast";
import { useGoogleLogin } from "@react-oauth/google";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";

const SignUp = ({ setCurrentComponent, setData }) => {
    const [, dispatch] = useStateValue();
    const [terms, setTerms] = useState(false);
    const [termError, setTermError] = useState("");
    const [passwordError, setPasswordError] = useState("")
    const navigateTo = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const doGoogleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            console.log(tokenResponse, "token response");
            verifyGoogleUser(tokenResponse);
        },
        onError: (error) => console.log("error", error),
    });
    const verifyGoogleUser = async (data) => {
        dispatch({ type: "SET_LOADING", status: true });
        const re = await googleLogin(data.access_token);
        dispatch({ type: "SET_LOADING", status: false });
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                // setError("general", {type: "general", message: re.data.status[0].ResponseMessage})
                toast.error(re.data.status[0].ResponseMessage);
                return;
            } else {
                localStorage.setItem("token", re.data.access_token);
                localStorage.setItem("refresh_token", re.data.refresh_token);
                localStorage.setItem("email", re.data.value?.email);
                localStorage.setItem(
                    "phone_number",
                    re.data.value?.phoneNumber
                );
                localStorage.setItem("firstName", re.data.value?.firstName);
                localStorage.setItem("lastName", re.data.value?.lastName);
                localStorage.setItem("coins", re?.data?.value?.coins)

                dispatch({
                    type: "SET_USER_LOGGED_IN",
                    status: true,
                });
                navigateTo("/get-details");
            }
        }
    };

    const getOtherDetails = async (data) => {
        if(data.password !== data.confirm_password){
            setPasswordError("Password and Confirm password must be same.")
            return;
        }
        if (!terms) {
            setTermError("Please accept google terms and privacy policy.");
            return;
        }

        dispatch({ type: "SET_LOADING", status: true });
        const response = await sendOtp({ phone_number: data?.phone_number });
        dispatch({ type: "SET_LOADING", status: false });
        console.log(response);
        if (response.status === 200) {
            setData(data);
            setCurrentComponent("otp");
        } else {
            toast.error(response?.response?.data?.status[0]?.ResponseMessage);
        }
    };

    return (
        <div className='signup-main'>
            <div className='my-container'>
                <div className='signup-container'>
                    <h3>Sign Up</h3>
                    <p className='desc'>
                        If you already have an account then please proceed with <span onClick={() => navigateTo("/login")}>login</span>
                    </p>

                    <div className='my-form'>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type='text'
                                    placeholder='First Name'
                                    {...register("first_name", {
                                        required: true,
                                    })}
                                />
                            </div>
                            {errors.first_name?.type === "required" && (
                                <p className='form-error'>
                                    First name is required.
                                </p>
                            )}
                        </div>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type='text'
                                    placeholder='last Name'
                                    {...register("last_name", {
                                        required: true,
                                    })}
                                />
                            </div>
                            {errors.last_name?.type === "required" && (
                                <p className='form-error'>
                                    Last name is required.
                                </p>
                            )}
                        </div>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type='number'
                                    placeholder='Phone number'
                                    {...register("phone_number", {
                                        required: true,
                                    })}
                                />
                            </div>
                            {errors.phone_number?.type === "required" && (
                                <p className='form-error'>
                                    Phone number is required.
                                </p>
                            )}
                        </div>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type='email'
                                    placeholder='E-mail'
                                    {...register("email", {
                                        required: true,
                                    })}
                                />
                            </div>
                            {errors.email?.type === "required" && (
                                <p className='form-error'>Email is required.</p>
                            )}
                        </div>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type={"password"}
                                    placeholder='Password'
                                    {...register("password", {
                                        required: true,
                                        pattern:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                                    })}
                                />
                            </div>
                            {errors.password?.type === "required" && (
                                <p className='form-error'>
                                    Password is required.
                                </p>
                            )}
                            {errors.password?.type === "pattern" && (
                                <p className='form-error'>
                                    Must Be Contains Uppercase, Lowercase
                                    Letters, Numbers, Special Characters And
                                    Length Is Greater Than 8 Character And Less
                                    Then 16 Character.
                                </p>
                            )}
                        </div>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type={"password"}
                                    placeholder='Confirm Password'
                                    {...register("confirm_password", {
                                        required: true,
                                        pattern:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                                    })}
                                />
                            </div>
                            {errors.confirm_password?.type === "required" && (
                                <p className='form-error'>
                                    Confirm password is required.
                                </p>
                            )}
                            {errors.confirm_password?.type === "pattern" && (
                                <p className='form-error'>
                                    Must Be Contains Uppercase, Lowercase
                                    Letters, Numbers, Special Characters And
                                    Length Is Greater Than 8 Character And Less
                                    Then 16 Character.
                                </p>
                            )}
                            {passwordError && <p className="form-error">{passwordError}</p>}
                        </div>
                        <div className='my-input'>
                            <div className='remember-forgot'>
                                <div
                                    className='remember'
                                    onClick={() => setTerms((pre) => !pre)}>
                                    <span className='check-box'>
                                        {terms && <CheckMark />}
                                    </span>
                                    <p>
                                        I agree to the Google Terms of Service
                                        and Privacy Policy
                                    </p>
                                </div>
                            </div>
                            <p className='form-error'>{termError}</p>
                        </div>
                        <div className='buttons'>
                            <button
                                className='primary-btn-black'
                                onClick={handleSubmit(getOtherDetails)}>
                                Sign Up
                            </button>
                            <span><hr /><p>OR</p><hr /></span>
                            <div className='socials'>
                                {/* <button className='facebook social-btn'>
                                    <FacebookIcon />
                                    <p>Facebook</p>
                                </button> */}
                                <button
                                    className='google social-btn'
                                    onClick={doGoogleLogin}>
                                    <GoogleIcon />
                                    <p>Google</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <img src={image} className='main-image' alt='' />
        </div>
    );
};

export default SignUp;
