import { useStateValue } from "../StateProvider"

function IsLiked(productId) {
    const[{likedProduct}] =useStateValue()
    const item = likedProduct?.filter(product => product.id === productId)
    if (item?.length > 0) {
        return true
    }
    return false
}
export default IsLiked