// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { CreateOrder, CreatePaymentOrder } from "../../../apiCall";
// import { CreateOrder, CreatePaymentOrder, createGuestOrder } from "../../../apiCall";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function DisplayRazorpay(orderData, address) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  // creating a new order
  const result = await CreatePaymentOrder({
    amount: orderData.total_amount,
  });
  
  if (result?.status !== 201) {
      alert("Something Went Wrong.");
      return;
    }
    const order = result?.data?.order;

  // Getting the order details back

  const options = {
    key: order["razorpay_merchant_key"], // Enter the Key ID generated from the Dashboard
    amount: order["razorpay_amount"],
    currency: order["currency"],
    name: "Pragati",
    description: "Test Transaction",
    order_id: order["razorpay_order_id"],
    handler: async function (response) {
      const data = {
        orderCreationId: order["razorpay_order_id"],
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };
      orderData.payment_id = data.razorpayPaymentId;
      orderData.order_id = data.razorpayOrderId;

      const orderRe = await CreateOrder(orderData);
      if (orderRe?.status === 201) {
        if(localStorage.getItem("coins")){
          localStorage.setItem("coins", orderRe?.data?.value?.coins)
        }else{
          sessionStorage.setItem("coins", orderRe?.data?.value?.coins)
        }
        toast.success(orderRe?.data?.status[0]?.Message);
        window.location.href = `/order-success?orderId=${orderRe?.data?.value?.order_id}&message=${orderRe?.data?.status[0]?.Message}`;
      } else {
        toast.error(orderRe.response?.data?.status[0]?.Message);
        window.location.href = `/order-fail?orderId=${orderRe?.data?.value?.order_id}&message=${orderRe?.data?.status[0]?.Message}`;
      }
    },
    prefill: {
      name: "Soumya Dey",
      email: "SoumyaDey@example.com",
      contact: "9999999999",
    },
    notes: {
      address: "Soumya Dey Corporate Office",
    },
    theme: {
      color: "#61dafb",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
