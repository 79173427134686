import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as HeartIcon } from "../../Assets/icons/heart.svg";
import "./wishlist.scss";
import { useStateValue } from "../../StateProvider";
import { domainName } from "../../Constants";
import { RemoveFromFavorites } from "../../hooks/RemoveFromFavorites";

const Wishlist = () => {
    const [{ likedProduct }, dispatch] = useStateValue();

    const [hoverItem, setHoverItem] = useState();
    const navigateTo = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);
    return (
        <div className='wishlist-main'>
            <span className='header'>
                <h3>WISHLIST</h3>
                <p>{likedProduct.length} Products</p>
            </span>
            <hr />
            {likedProduct.length > 0 ? (
                <div className='products'>
                    {likedProduct.map((product, indx) => (
                        <div
                            className='product'
                            key={"product-" + indx}
                            onMouseEnter={() => setHoverItem(product.id)}
                            onMouseLeave={() => setHoverItem()}>
                            <div
                                onClick={() =>
                                    navigateTo("/product-details/" + product.id)
                                }>
                                {hoverItem === product?.id ? (
                                    product?.hover_image?.includes("blob:") ? (
                                        <img
                                            src={`${product?.hover_image}`}
                                            alt=''
                                        />
                                    ) : (
                                        <div className='loading-statistics-container hover-image'>
                                            <div className='component-loading statistics-loading-cmp'></div>
                                            <div className='component-loading statistics-loading-cmp'></div>
                                            <div className='component-loading statistics-loading-cmp'></div>
                                        </div>
                                    )
                                ) : product?.main_image?.includes("blob:") ? (
                                    <img
                                        src={`${product?.main_image}`}
                                        alt=''
                                    />
                                ) : (
                                    <div className='loading-statistics-container hover-image'>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                    </div>
                                )}
                            </div>
                            <div className='content'>
                                <div className='line1'>
                                    <p className='title'>{product?.title}</p>
                                    <p className='tag'>
                                        {product?.category_title}
                                    </p>
                                </div>
                                <div className='line1'>
                                    <p className='bold'>₹{product?.price}</p>
                                    <p
                                        onClick={() =>
                                            RemoveFromFavorites(
                                                product.id,
                                                dispatch
                                            )
                                        }>
                                        <HeartIcon />
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <h3
                    style={{
                        minHeight: "40vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    Your wishlist is empty.
                </h3>
            )}
        </div>
    );
};

export default Wishlist;
