import React, { useState } from "react";

import { ReactComponent as CheckMark } from "../../../../Assets/icons/tick-circle.svg";
import { ReactComponent as MaleIcon } from "../../../../Assets/icons/male.svg";
import { ReactComponent as FemaleIcon } from "../../../../Assets/icons/female.svg";
import "./otherDetails.scss";
import image from "../../../../Assets/images/img10.svg";
import { completeProfile } from "../../../../apiCall";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../../StateProvider";

const OtherDetails = () => {
    const [gender, setGender] = useState()
    const [date, setDate] = useState()
    const navigateTo = useNavigate()
    const [, dispatch] = useStateValue();

    const submitDetails = async () => {
        if(!gender || !date){
            toast.error("Gender and date is required.")
            return;
        }
        dispatch({ type: "SET_LOADING", status: true });
        const response = await completeProfile({ gender: gender, DOB: date });
        dispatch({ type: "SET_LOADING", status: false });
        console.log(response)
        if (response.status === 200) {
            navigateTo('/')
        } else {
            toast.error(response?.response?.data?.status[0]?.ResponseMessage);
        }
    }
    return (
        <div className='other-details-main'>
            <div className='my-container'>
                <div className='other-details-container'>
                    <h3>Please provide below details.</h3>
                    <p className='desc'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                    </p>
                    <div className='my-form'>
                        <div className='gender'>
                            <div className={`type male ${gender === "Male" ? "selected" : ""}`} onClick={() => setGender("Male")}>
                                <CheckMark className="check" />
                                <MaleIcon />
                            </div>
                            <div className={`type female ${gender === "Female" ? "selected" : ""}`} onClick={() => setGender("Female")}>
                                <CheckMark className="check" />
                                <FemaleIcon />
                            </div>
                        </div>
                        <span>
                            <div className='input-grp dates'>
                                <span>
                                    <label htmlFor='Anniversary Date'>
                                        Anniversary Date
                                    </label>
                                    <input
                                        type='date'
                                        name=''
                                        id=''
                                        placeholder='Birthday date'
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </span>
                            </div>
                            <p>
                                *Anniversary date is only for
                                promotional offers
                            </p>
                        </span>

                        <div className='buttons'>
                            <button className='primary-btn-black' onClick={submitDetails}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <img src={image} className='main-image' alt='' />
        </div>
    );
};

export default OtherDetails;
