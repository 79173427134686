import React, { useEffect, useRef, useState } from "react";

import image from "../../../../Assets/images/img12.svg";
import "./otpVerification.scss";
import toast from "react-hot-toast";
import { SignUp, sendOtp, updateProfile, verifyOtp } from "../../../../apiCall";
import { useStateValue } from "../../../../StateProvider";

const OtpVerification = ({ data, setCurrentComponent }) => {
    const [letter1, setLetter1] = useState();
    const [letter2, setLetter2] = useState();
    const [letter3, setLetter3] = useState();
    const [letter4, setLetter4] = useState();
    const [letter5, setLetter5] = useState();
    const [letter6, setLetter6] = useState();

    const inputref1 = useRef();
    const inputref2 = useRef();
    const inputref3 = useRef();
    const inputref4 = useRef();
    const inputref5 = useRef();
    const inputref6 = useRef();

    const [, dispatch] = useStateValue();

    const handleFocus = () => {
        if (!letter1) {
            inputref1.current.focus();
        } else if (!letter2) {
            inputref2.current.focus();
        } else if (!letter3) {
            inputref3.current.focus();
        } else if (!letter4) {
            inputref4.current.focus();
        } else if (!letter5) {
            inputref5.current.focus();
        } else if (!letter6) {
            inputref6.current.focus();
        }
    };

    useEffect(() => {
        handleFocus();
    }, [letter1, letter2, letter3, letter4, letter5, letter6]);

    const verifyPhoneOtp = async () => {
        const otp = letter1 + letter2 + letter3 + letter4 + letter5 + letter6;

        dispatch({ type: "SET_LOADING", status: true });
        const response = await verifyOtp({
            phone_number: data?.phone_number,
            phone_number_otp: otp,
        });
        dispatch({ type: "SET_LOADING", status: false });
        console.log(response);
        if (response.status === 200) {
            localStorage.setItem("signup_token", response?.data?.token);
            
            if (data?.email) {
                dispatch({ type: "SET_LOADING", status: true });
                const signupRes = await SignUp({ ...data, user_type: "buyer" });
                dispatch({ type: "SET_LOADING", status: false });
                console.log(signupRes);
                if (signupRes.status === 201) {
                    dispatch({ type: "SET_LOGIN_STATUS", status: true });
                    localStorage.setItem("token", signupRes.data.access_token);
                    localStorage.setItem("refresh_token", signupRes.data.refresh_token);
                    localStorage.setItem("email", signupRes.data.value?.email);
                    localStorage.setItem("phone_number", signupRes.data.value?.phoneNumber);
                    localStorage.setItem("firstName", signupRes.data.value?.firstName);
                    localStorage.setItem("lastName", signupRes.data.value?.lastName);
                    localStorage.setItem("coins", signupRes?.data?.value?.coins)
                    dispatch({ type: "SET_USER_LOGGED_IN", status: true });
                    setCurrentComponent("other");
                } else {
                    toast.error(signupRes?.response?.data?.status[0]?.Message);
                }
            } else {
                dispatch({ type: "SET_LOADING", status: true });
                const updateResponse = await updateProfile({
                    ...data,
                    email:
                    localStorage.getItem("email") ||
                    sessionStorage.getItem("email"),
                    gender: "",
                    DOB: "",
                });
                dispatch({ type: "SET_LOADING", status: false });
                console.log(updateResponse);
                if (updateResponse.status === 200) {
                    if(localStorage.getItem("token")){
                        localStorage.setItem("phone_number", data?.phone_number);
                        localStorage.setItem("firstName", data?.first_name);
                        localStorage.setItem("lastName", data?.last_name);
                    }else{
                        localStorage.setItem("phone_number", data?.phone_number);
                        localStorage.setItem("firstName", data?.first_name);
                        localStorage.setItem("lastName", data?.last_name);
                    }
                    setCurrentComponent("other");
                } else {
                    toast.error(
                        updateResponse?.response?.data?.status[0]
                            ?.ResponseMessage
                    );
                }
            }
        } else {
            toast.error(response?.response?.data?.status[0]?.ResponseMessage);
        }
    };

    const sendOtpRes = async () => {
        dispatch({ type: "SET_LOADING", status: true });
        const response = await sendOtp({ phone_number: data?.phone_number });
        dispatch({ type: "SET_LOADING", status: false });
        console.log(response);
        if (response.status === 200) {
            toast.success("OTP send again.");
        } else {
            toast.error(response?.response?.data?.status[0]?.ResponseMessage);
        }
    };
    return (
        <div className='otp-verification-main'>
            <div className='otp-container'>
                <div className='otp-container'>
                    <h3>Enter OTP</h3>
                    <p className='desc'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                    </p>
                    <div className='otp'>
                        <input
                            type='text'
                            value={letter1}
                            onChange={(e) => setLetter1(e.target.value)}
                            ref={inputref1}
                        />
                        <input
                            type='text'
                            value={letter2}
                            onChange={(e) => setLetter2(e.target.value)}
                            ref={inputref2}
                        />
                        <input
                            type='text'
                            value={letter3}
                            onChange={(e) => setLetter3(e.target.value)}
                            ref={inputref3}
                        />
                        <input
                            type='text'
                            value={letter4}
                            onChange={(e) => setLetter4(e.target.value)}
                            ref={inputref4}
                        />
                        <input
                            type='text'
                            value={letter5}
                            onChange={(e) => setLetter5(e.target.value)}
                            ref={inputref5}
                        />
                        <input
                            type='text'
                            value={letter6}
                            onChange={(e) => setLetter6(e.target.value[0])}
                            ref={inputref6}
                        />
                    </div>
                    <button
                        className='primary-btn-black'
                        onClick={() => verifyPhoneOtp()}>
                        Submit
                    </button>
                    <p className='did'>
                        Didn't recive OTP?{" "}
                        <span onClick={() => sendOtpRes()}>Resend</span>
                    </p>
                </div>
            </div>
            <img src={image} className='main-image' alt='' />
        </div>
    );
};

export default OtpVerification;
