import React, { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../Assets/icons/close.svg";
import "./cancelOrder.scss";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { GetOrderDetails, CancelOrderById, GenerateRefreshToken } from "../../apiCall";
import { domainName } from "../../Constants";
import { useStateValue } from "../../StateProvider";


const CancelOrder = ({ order_id }) => {
    const [, dispatch] = useStateValue()
    const [details, setDetails] = useState();
    const [detailsImages, setDetailsImages] = useState();
    const [reason, setReason] = useState()
    const [reasonError, setReasonError] = useState("")
    const navigateTo = useNavigate();

    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });
            const Re = await GetOrderDetails(order_id);
            dispatch({ type: "SET_IS_LOADING", status: false });
            console.log(Re);
            
            if (Re.status === 401) {
                dispatch({ type: "SET_IS_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_IS_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    getData();
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (Re?.status === 200) {
                console.log(Re);
                setDetails(Re?.data?.value);
                setDetailsImages(Re?.data?.value);
            } 
            else if (Re?.response?.status === 401) {
                
                dispatch({type:"SET_USER_LOGGED_IN",status:false})
                dispatch({type:"SET_CART_ITEM",status:[]})
                dispatch({type:"SET_LIKED_PRODUCT",status:[]})
                dispatch({type:"SET_USER_DETAIL",detail:{}})
                localStorage.removeItem("token")
                localStorage.removeItem("user_details")
                localStorage.removeItem("cartItem")
                navigateTo("/login")
              } 
            else {
                toast.error(Re?.response.data?.status[0].ResponseMessage);
            }
        };

        getData();
    }, []);

    const cancelOrder = async () => {
        if(!reason){
            setReasonError("Please enter valid reason.")
            return;
        }
        const data = {"orderId": String(order_id),
            "reason": reason,
        }
        dispatch({ type: "SET_IS_LOADING", status: true });
        const Re = await CancelOrderById(data);
        dispatch({ type: "SET_IS_LOADING", status: false });
        console.log(Re);
        
        if (Re.status === 401) {
            dispatch({ type: "SET_IS_LOADING", status: true });
            const refreshRe = await GenerateRefreshToken();
            dispatch({ type: "SET_IS_LOADING", status: false });

            console.log(refreshRe, "REFRESH RE");

            if (refreshRe?.data?.access_token) {
                localStorage.setItem(
                    "refresh_token",
                    refreshRe?.data?.refresh_token
                );
                localStorage.setItem("token", refreshRe?.data?.access_token);
                cancelOrder();
            } else {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.clear();
                sessionStorage.clear();
                navigateTo("/login");
            }
        } else if (Re?.status === 200) {
            toast.success("Cancel order request submitted")
            dispatch({
                type: "SET_CANCEL_ORDER_MODAL",
                status: false,
            });
        }
        else if (Re?.response?.status === 401) {
            
            dispatch({type:"SET_USER_LOGGED_IN",status:false})
            dispatch({type:"SET_CART_ITEM",status:[]})
            dispatch({type:"SET_LIKED_PRODUCT",status:[]})
            dispatch({type:"SET_USER_DETAIL",detail:{}})
            localStorage.removeItem("token")
            localStorage.removeItem("user_details")
            localStorage.removeItem("cartItem")
            navigateTo("/login")
          } 
          else{
            toast.error(Re?.response.data?.status[0].ResponseMessage);
        }
    };

    const setCancelOrderModelShow = (status) => {
        dispatch({
            type: "SET_CANCEL_ORDER_MODAL",
            status: status,
        });
    };

    useEffect(() => {
        details?.products?.forEach((prod) => {
            console.log("EACH PRODUCT", prod);
            const xhr = new XMLHttpRequest();
            xhr.open(
                "GET",
                domainName + "/uploads/" + prod?.product_details?.main_image,
                true
            );
            xhr.responseType = "blob";
            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = Math.round(
                        (event.loaded / event.total) * 100
                    );
                    // setProgress(percentComplete);
                }
            };
            xhr.onload = () => {
                const url = URL.createObjectURL(xhr.response);
                // displayProducts.push({ ...prod, main_image: url, loaded: true });

                console.log(url);
                setDetailsImages((pre) => {
                    const my_list = [];
                    pre?.products.forEach((myProd) => {
                        if (
                            myProd?.product_details?.id ===
                            prod?.product_details?.id
                        ) {
                            my_list.push({
                                ...myProd,
                                product_details: {
                                    ...myProd?.product_details,
                                    main_image: url,
                                },
                            });
                        } else {
                            my_list.push(myProd);
                        }
                    });

                    console.log("ANSWER", { ...pre, products: my_list }, pre);
                    return { ...pre, products: my_list };
                });
            };
            xhr.send();
        });
    }, [details]);

    return (
        <div className='replace-order-model'>
            <p className='head'>ORDER DETAILS #{details?.uuid}</p>
            <CloseIcon className='close-icon' onClick={() => setCancelOrderModelShow(false)}/>
            <div className='product-items'>
                {detailsImages?.products?.map((item) => (
                    <div
                        className='item'
                        key={"item-" + item?.product_details?.id}>
                        {item?.product_details?.main_image?.includes(
                            "blob:"
                        ) ? (
                            <img
                                src={item?.product_details?.main_image}
                                alt=''
                                onClick={() =>
                                    navigateTo(
                                        "/product-details/" +
                                            item?.product_details?.id
                                    )
                                }
                            />
                        ) : (
                            <div className='loading-statistics-container'>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                            </div>
                        )}
                        <div className='name' onClick={() =>
                                navigateTo(
                                    "/product-details/" +
                                        item?.product_details?.id
                                )
                            }>
                            <p className='bold'>
                                {item?.product_details?.title}
                            </p>
                            <p>#{item?.product_details?.id}</p>
                        </div>
                        {/* <p className='detail'>{item?.color}</p> */}
                        <p className='detail'>{item?.size}</p>
                        <p className='detail'>x{item?.quantity}</p>
                        <p className='detail price'>
                            {item?.product_details?.price.toLocaleString(
                                "en-US",
                                {
                                    style: "currency",
                                    currency: "INR",
                                }
                            )}
                        </p>
                    </div>
                ))}
            </div>
            <div className='form'>
                <p className='title'>REASON</p>
                <textarea
                    name=''
                    id=''
                    placeholder='Please mention the reason' value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
                <p className="form-error">{reasonError}</p>
            </div>
            <button className='primary-btn-black' onClick={() => cancelOrder()}>SUBMIT</button>
        </div>
    );
};

export default CancelOrder;
