import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as ArrowIcon } from "../../Assets/icons/arrow.svg";
// import Img1 from "../../Assets/images/img1.svg";
// import Img2 from "../../Assets/images/img2.svg";
// import Img3 from "../../Assets/images/img3.svg";
// import Img4 from "../../Assets/images/img4.svg";
// import Img5 from "../../Assets/images/img5.svg";
// import Img6 from "../../Assets/images/img6.svg";
// import Img7 from "../../Assets/images/img7.svg";
// import Img8 from "../../Assets/images/img8.svg";
// import Img9 from "../../Assets/images/img9.svg";
// import Img11 from "../../Assets/images/img11.svg";
import "./home.scss";
import { Link, useNavigate } from "react-router-dom";
import { GetHomeContent } from "../../apiCall";
import { useQuery } from "react-query";
import { CACHE_TIME, domainName } from "../../Constants";
import toast from "react-hot-toast";

// const section_1_images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8];
const Home = () => {
    const [content, setContent] = useState();
    const [contentImages, setContentImages] = useState({});
    useQuery(["home-content"], () => GetHomeContent(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // console.log(data);
            if (data.data?.status[0].Error === "False") {
                setContent(data.data.value);
                setContentImages(data.data.value);
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
    const navigateTo = useNavigate();

    const [remainingTime, setRemainingTime] = useState();

    useEffect(() => {
        if (content?.offerCollection?.image.includes(".")) {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", domainName + content?.offerCollection?.image, true);
            xhr.responseType = "blob";
            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = Math.round(
                        (event.loaded / event.total) * 100
                    );
                    // setProgress(percentComplete);
                }
            };
            xhr.onload = () => {
                const url = URL.createObjectURL(xhr.response);
                setContentImages((pre) => {
                    return {
                        ...pre,
                        offerCollection: {
                            ...pre.offerCollection,
                            image: url,
                            loaded: true,
                        },
                    };
                });
            };
            xhr.send();
        }
    }, [content?.offerCollection?.image]);

    useEffect(() => {
        content?.displayProducts?.forEach((prod) => {
            if (prod?.image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open("GET", domainName + "/uploads/" + prod?.image, true);
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, image: url, loaded: true });

                    setContentImages((pre) => {
                        return {
                            ...pre,
                            displayProducts: pre?.displayProducts?.map(
                                (myProd) => {
                                    if (myProd?.id === prod?.id) {
                                        return {
                                            ...myProd,
                                            image: url,
                                            loaded_main: true,
                                        };
                                    } else {
                                        return myProd;
                                    }
                                }
                            ),
                        };
                    });
                };
                xhr.send();
            }
            if (prod?.hover_image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + "/uploads/" + prod?.hover_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    setContentImages((pre) => {
                        return {
                            ...pre,
                            displayProducts: pre?.displayProducts?.map(
                                (myProd) => {
                                    if (myProd?.id === prod?.id) {
                                        return {
                                            ...myProd,
                                            hover_image: url,
                                            loaded_hover: true,
                                        };
                                    } else {
                                        return myProd;
                                    }
                                }
                            ),
                        };
                    });
                };
                xhr.send();
            }
        });
    }, [content?.displayProducts]);

    useEffect(() => {
        function getTimeRemaining(targetDate) {
            const now = new Date();
            const timeDifference = targetDate - now;

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
            const seconds = Math.floor((timeDifference / 1000) % 60);

            setRemainingTime({
                days: String(days).padStart(2, "0"),
                hours: String(hours).padStart(2, "0"),
                minutes: String(minutes).padStart(2, "0"),
                seconds: String(seconds).padStart(2, "0"),
            });
        }
        //Implementing the setInterval method
        const interval = setInterval(() => {
            getTimeRemaining(new Date(content?.offerCollection?.ends_at));
        }, 1000);

        //Clearing the interval
        return () => clearInterval(interval);
    }, [content?.offerCollection?.ends_at]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (
        <div className='home-main'>
            <div className='section-1'>
                <div className='texts-container'>
                    <h1>
                        Getting You
                        <br />
                        Noticed
                    </h1>
                    <p>
                        Discover the latest fashion trends with our new arrivals
                        in clothing!
                    </p>
                    <button
                        className='primary-btn-white'
                        onClick={() => navigateTo("/products")}>
                        <span>SHOP NOW</span>
                        <ArrowIcon />
                    </button>
                </div>
                {/* <div className='images'>
                    {section_1_images.map((img, indx) => (
                        <img key={"section-1-img-" + indx} src={img} alt='' />
                    ))}
                </div> */}
                <div className='images-bg'></div>
            </div>
            {content?.offerCollection && (
                <div className='offer'>
                    {content?.offerCollection?.offertext}
                </div>
            )}
            {content?.offerCollection && (
                <div className='section-2'>
                    <div className='container'>
                        <h2>{content?.offerCollection?.title}</h2>
                        <button
                            className='primary-btn-red'
                            onClick={() =>
                                navigateTo(
                                    "/products/" +
                                        content?.offerCollection?.category
                                )
                            }>
                            <span>SHOP NOW</span>
                            <ArrowIcon />
                        </button>
                        <div className='timer'>
                            <span className='time-unit'>
                                <p className='number'>{remainingTime?.days}</p>
                                <p className='unit'>Days</p>
                            </span>
                            <span className='time-unit'>
                                <p className='number'>:</p>
                            </span>
                            <span className='time-unit'>
                                <p className='number'>{remainingTime?.hours}</p>
                                <p className='unit'>Hours</p>
                            </span>
                            <span className='time-unit'>
                                <p className='number'>:</p>
                            </span>
                            <span className='time-unit'>
                                <p className='number'>
                                    {remainingTime?.minutes}
                                </p>
                                <p className='unit'>Minutes</p>
                            </span>
                            <span className='time-unit'>
                                <p className='number'>:</p>
                            </span>
                            <span className='time-unit'>
                                <p className='number'>
                                    {remainingTime?.seconds}
                                </p>
                                <p className='unit'>Seconds</p>
                            </span>
                        </div>
                    </div>
                    {contentImages?.offerCollection?.loaded ? (
                        <img
                            src={contentImages?.offerCollection?.image}
                            className='background-img'
                            alt=''
                        />
                    ) : 
                        <div className='loading-statistics-container background-img'>
                            <div className='component-loading statistics-loading-cmp'></div>
                            <div className='component-loading statistics-loading-cmp'></div>
                            <div className='component-loading statistics-loading-cmp'></div>
                        </div>
                    }
                </div>
            )}
            {content?.displayProductsHeading && (
                <div className='section-3'>
                    <div className='head'>
                        <h3>{content?.displayProductsHeading?.title}</h3>
                        <span className='text-container'>
                            <p className='desc'>
                                {content?.displayProductsHeading?.description}
                            </p>
                            <p className='shop'>
                                <Link to='/products'>
                                    <span>Shop All</span> <ArrowIcon />
                                </Link>
                            </p>
                        </span>
                    </div>
                    <div className='images-container'>
                        {contentImages?.displayProducts?.map((prod, indx) => (
                            <span
                                onClick={() =>
                                    navigateTo("/product-details/" + prod?.id)
                                }
                                key={"display-" + prod?.id}>
                                {(prod?.loaded_hover && prod?.loaded_main) ? (
                                    <img
                                        src={
                                            prod?.image
                                        }
                                        alt=''
                                    />
                                ) : 
                                <div className='loading-statistics-container main-img'>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                </div>}
                                {(prod?.loaded_hover && prod?.loaded_main) ? (
                                    <img
                                        src={
                                            prod?.hover_image
                                        }
                                        className='hover-image'
                                        alt=''
                                    />
                                ) : 
                                <div className='loading-statistics-container hover-image'>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                </div>}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
