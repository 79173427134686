import React, { useEffect } from "react";

import { ReactComponent as ContactImg } from "../../Assets/images/contact.svg";
import "./contact.scss";
import { useForm } from "react-hook-form";
import { SubmitContactRequest } from "../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../StateProvider";

const Contact = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [, dispatch] = useStateValue();

    const submitForm = async (data) => {
        dispatch({ type: "SET_LOADING", status: true });
        const Re = await SubmitContactRequest(data);
        dispatch({ type: "SET_LOADING", status: false });
        // console.log(Re);

        if (Re.data?.status[0].Error === "False") {
            toast.success(
                "We have received your query and will get back to you soon."
            );
            reset({email: "", name: "", message: ""});
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (
        <div className='contact-us-main'>
            <div className='head'>
                <div>
                    <h3>We would love to hear from you.</h3>
                    <p>
                        If you have any query ,you can contact us here. We would
                        love to hear from you.
                    </p>
                </div>
                <ContactImg />
            </div>
            <div className='contact-form'>
                <div className='input-container'>
                    <div className='input-grp'>
                        <label htmlFor='name'>Name</label>
                        <input
                            type='text'
                            {...register("name", {
                                required: true,
                                maxLength: 70,
                            })}
                        />
                        {errors.name?.type === "required" && (
                            <p className='form-error'>Name is required.</p>
                        )}
                        {errors.name?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of name can not be more than 70.
                            </p>
                        )}
                    </div>
                    <div className='input-grp'>
                        <label htmlFor='email'>Email</label>
                        <input
                            type='email'
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message: "Enter a valid email",
                                },
                            })}
                        />
                        {errors.email?.type === "required" && (
                            <p className='form-error'>Email is required.</p>
                        )}
                        {errors.email?.type === "pattern" && (
                            <p className='form-error'>{errors.email?.message}</p>
                        )}
                    </div>
                </div>
                <div className='input-grp'>
                    <label htmlFor='message'>Message</label>
                    <textarea
                        name='message'
                        id=''
                        {...register("message", {
                            required: true,
                        })}></textarea>
                    {errors.message?.type === "required" && (
                        <p className='form-error'>
                            Message field can not be empty.
                        </p>
                    )}
                </div>
                <button className='primary-btn-black' onClick={handleSubmit(submitForm)}>SEND MESSAGE</button>
            </div>
        </div>
    );
};

export default Contact;
