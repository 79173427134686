import React, { useEffect, useState } from "react";

// import ProductImg from "../../Assets/images/img4.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/close.svg";
import "./orderDetails.scss";
import { GenerateRefreshToken, GetOrderDetails } from "../../apiCall";
import toast from "react-hot-toast";
import { domainName } from "../../Constants";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";

// const items = [
//     {
//         id: 1,
//         img: ProductImg,
//         name: "XYZ T-Shirt",
//         price: 89.99,
//         color: "White",
//         size: "XS",
//         quantity: 1,
//     },
//     {
//         id: 2,
//         img: ProductImg,
//         name: "XYZ T-Shirt",
//         price: 89.99,
//         color: "White",
//         size: "XS",
//         quantity: 1,
//     },
//     {
//         id: 3,
//         img: ProductImg,
//         name: "XYZ T-Shirt",
//         price: 89.99,
//         color: "White",
//         size: "XS",
//         quantity: 1,
//     },
//     {
//         id: 4,
//         img: ProductImg,
//         name: "XYZ T-Shirt",
//         price: 89.99,
//         color: "White",
//         size: "XS",
//         quantity: 1,
//     },
//     {
//         id: 5,
//         img: ProductImg,
//         name: "XYZ T-Shirt",
//         price: 89.99,
//         color: "White",
//         size: "XS",
//         quantity: 1,
//     },
//     {
//         id: 6,
//         img: ProductImg,
//         name: "XYZ T-Shirt",
//         price: 89.99,
//         color: "White",
//         size: "XS",
//         quantity: 1,
//     },
// ];
const OrderDetails = ({ order_id }) => {
    const [details, setDetails] = useState();
    const [detailsImages, setDetailsImages] = useState();
    const [, dispatch] = useStateValue();

    const setOrderDetailsModelShow = (status) => {
        dispatch({
            type: "SET_ORDER_DETAILS_MODAL",
            status: status,
        });
    };

    const navigateTo = useNavigate();
    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });
            const Re = await GetOrderDetails(order_id);
            dispatch({ type: "SET_IS_LOADING", status: false });
            console.log(Re);

            if (Re.status === 401) {
                dispatch({ type: "SET_IS_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_IS_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    getData();
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (Re?.status === 200) {
                console.log(Re);
                setDetails(Re?.data?.value);
                setDetailsImages(Re?.data?.value);
            } else if (Re?.response?.status === 401) {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.removeItem("token");
                localStorage.removeItem("user_details");
                localStorage.removeItem("cartItem");
                navigateTo("/login");
            } else {
                toast.error(Re?.response?.data?.status[0].ResponseMessage);
            }
        };

        getData();
    }, []);

    useEffect(() => {
        details?.products?.forEach((prod) => {
            console.log("EACH PRODUCT", prod);
            const xhr = new XMLHttpRequest();
            xhr.open(
                "GET",
                domainName + "/uploads/" + prod?.product_details?.main_image,
                true
            );
            xhr.responseType = "blob";
            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = Math.round(
                        (event.loaded / event.total) * 100
                    );
                    // setProgress(percentComplete);
                }
            };
            xhr.onload = () => {
                const url = URL.createObjectURL(xhr.response);
                // displayProducts.push({ ...prod, main_image: url, loaded: true });

                console.log(url);
                setDetailsImages((pre) => {
                    const my_list = [];
                    pre?.products.forEach((myProd) => {
                        if (
                            myProd?.product_details?.id ===
                            prod?.product_details?.id
                        ) {
                            my_list.push({
                                ...myProd,
                                product_details: {
                                    ...myProd?.product_details,
                                    main_image: url,
                                },
                            });
                        } else {
                            my_list.push(myProd);
                        }
                    });

                    console.log("ANSWER", { ...pre, products: my_list }, pre);
                    return { ...pre, products: my_list };
                });
            };
            xhr.send();
        });
    }, [details]);
    return (
        <div className='order-details-model'>
            <p className='head'>ORDER DETAILS #{details?.uuid}</p>
            <CloseIcon
                className='close-icon'
                onClick={() => setOrderDetailsModelShow(false)}
            />
            <div className='product-items'>
                {detailsImages?.products?.map((item) => (
                    <div
                        className='item'
                        key={"item-" + item?.product_details?.id}>
                        {item?.product_details?.main_image?.includes(
                            "blob:"
                        ) ? (
                            <img
                                src={item?.product_details?.main_image}
                                alt=''
                                onClick={() =>
                                    navigateTo(
                                        "/product-details/" +
                                            item?.product_details?.id
                                    )
                                }
                            />
                        ) : (
                            <div className='loading-statistics-container'>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                            </div>
                        )}
                        <div className='name' onClick={() =>
                                navigateTo(
                                    "/product-details/" +
                                        item?.product_details?.id
                                )
                            }>
                            <p className='bold'>
                                {item?.product_details?.title}
                            </p>
                            <p>#{item?.product_details?.id}</p>
                        </div>
                        {/* <p className='detail'>{item?.color}</p> */}
                        <p className='detail'>{item?.size}</p>
                        <p className='detail'>x{item?.quantity}</p>
                        <p className='detail price'>
                            {item?.product_details?.price.toLocaleString(
                                "en-US",
                                {
                                    style: "currency",
                                    currency: "INR",
                                }
                            )}
                        </p>
                    </div>
                ))}
                {detailsImages?.products?.map((item) => (
                    <div
                        className='item'
                        key={"item-" + item?.product_details?.id}>
                        {item?.product_details?.main_image?.includes(
                            "blob:"
                        ) ? (
                            <img
                                src={item?.product_details?.main_image}
                                alt=''
                                onClick={() =>
                                    navigateTo(
                                        "/product-details/" +
                                            item?.product_details?.id
                                    )
                                }
                            />
                        ) : (
                            <div className='loading-statistics-container'>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                            </div>
                        )}
                        <div className='name' onClick={() =>
                                navigateTo(
                                    "/product-details/" +
                                        item?.product_details?.id
                                )
                            }>
                            <p className='bold'>
                                {item?.product_details?.title}
                            </p>
                            <p>#{item?.product_details?.id}</p>
                        </div>
                        {/* <p className='detail'>{item?.color}</p> */}
                        <p className='detail'>{item?.size}</p>
                        <p className='detail'>x{item?.quantity}</p>
                        <p className='detail price'>
                            {item?.product_details?.price.toLocaleString(
                                "en-US",
                                {
                                    style: "currency",
                                    currency: "INR",
                                }
                            )}
                        </p>
                    </div>
                ))}
            </div>
            <div className='summary'>
                <p className='title'>ORDER SUMMARY</p>
                <div className='details'>
                    <p>Total</p>
                    <p>{details?.total_amount}</p>
                </div>
                {details?.cod_fee ? (
                    <div className='details'>
                        <p>COD Fees</p>
                        <p>{details?.cod_fee}</p>
                    </div>
                ) : (
                    <></>
                )}
                <div className='details'>
                    <p>Shipping Fees</p>
                    <p>{details?.shipping_fee}</p>
                </div>
                <div className='details'>
                    <p>Donation Amount</p>
                    <p>{details?.donation_amount}</p>
                </div>
                <div className='details'>
                    <p>Tax</p>
                    <p>{details?.tax}</p>
                </div>
                <div className='details'>
                    <p>Discount</p>
                    <p>{details?.totak_discount}</p>
                </div>
            </div>
            <button className='primary-btn-black'>DOWNLOAD INVOICE</button>
        </div>
    );
};

export default OrderDetails;
