const initialCartItem = localStorage?.getItem("cartItem");
let parsedCartItem = [];
try {
  if (typeof initialCartItem === "string") {
    parsedCartItem = JSON.parse(initialCartItem);
  }
} catch (error) {
  console.error("Error parsing cartItem JSON:", error);
}

export const initialState = {
  isLoggedIn:
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false,
  userDetail:
    localStorage.getItem("user_details") ||
    sessionStorage.getItem("user_details"),

  showCart: false,
  showOrderDetailsModel: false,
  showReplaceOrderModel: false,
  showCancelOrderModel: false,
  likedProduct: Array.isArray(localStorage?.getItem("likedProduct"))
    ? JSON.parse(localStorage?.getItem("likedProduct"))
    : [],
  cartItem: parsedCartItem,
  isLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_USER_LOGGED_IN":
      return {
        ...state,
        isLoggedIn: action.status,
      };
    case "SET_IS_LOADING":
      if (action.status) {
        document.body.style.overflow = "hidden";
        window.scrollTo(0, 0);
      } else {
        document.body.style.overflow = "auto";
      }
      return {
        ...state,
        isLoading: action.status,
      };
    case "SET_USER_DETAIL":
      return {
        ...state,
        userDetail: action.detail,
      };

    case "SET_CART_SHOW":
      return {
        ...state,
        showCart: action.status,
      };

    case "SET_ORDER_DETAILS_MODAL":
      return {
        ...state,
        showOrderDetailsModel: action.status,
      };

    case "SET_REPLACE_ORDER_MODAL":
      return {
        ...state,
        showReplaceOrderModel: action.status,
      };
    case "SET_CANCEL_ORDER_MODAL":
      return {
        ...state,
        showCancelOrderModel: action.status,
      };
    case "SET_LIKED_PRODUCT":
      localStorage.setItem("likedProduct", JSON.stringify(action.data));
      return {
        ...state,
        likedProduct: action.data ? action.data : [],
      };

    case "SET_CART_ITEM":
      // localStorage.setItem("cartItem", JSON.stringify(action.data));
      return {
        ...state,
        cartItem: action.data,
      };
    case "ADD_TO_CART":
      let cartItemIndex;
      cartItemIndex = state.cartItem.findIndex(
        (product) =>
          product.id === action.cartData.id &&
          product.size.id === action.cartData?.size?.id
      );

      if (cartItemIndex > -1) {
        let newList = state.cartItem;
        const item = newList[cartItemIndex];
        const newQuantity = newList[cartItemIndex].quantity + 1;
        newList[cartItemIndex].quantity = newQuantity;
        // localStorage.setItem("cartItem", JSON.stringify(newList));
        return {
          ...state,
          cartItem: newList,
        };
      } else {
        // localStorage.setItem(
        //   "cartItem",
        //   JSON.stringify([...state.cartItem, action.cartData])
        // );
        return {
          ...state,
          cartItem: [...state.cartItem, action.cartData],
        };
      }
    case "UPDATE_CART":
      let updateCartIndex;

      updateCartIndex = state.cartItem.findIndex(
        (product) =>
          product.id === action.cartData.id &&
          product.size.id === action.cartData.size.id
      );

      if (updateCartIndex > -1) {
        let newList = state.cartItem;
        newList[updateCartIndex].quantity = action.cartData.quantity;
        // localStorage.setItem("cartItem", JSON.stringify(newList));
        return {
          ...state,
          cartItem: newList,
        };
      } else {
        // localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
        return {
          ...state,
          cartItem: state.cartItem,
        };
      }
    
    case "RESET_CART":
      return {
        ...state,
        cartItem: action.status,
      };

    case "REMOVE_FROM_CART":
      let removeCartIndex;

      removeCartIndex = state.cartItem.findIndex(
        (product) =>
          product.id === action.cartData.id &&
          product.size.id === action.cartData.size.id
      );

      if (removeCartIndex > -1) {
        let newList = state.cartItem;
        newList.splice(removeCartIndex, 1);
        // localStorage.setItem("cartItem", JSON.stringify(newList));
        return {
          ...state,
          cartItem: newList,
        };
      } else {
        // localStorage.setItem("cartItem", JSON.stringify(state.cartItem));
        return {
          ...state,
          cartItem: state.cartItem,
        };
      }

    case "ADD_TO_LIKED_PRODUCT":
      localStorage.setItem(
        "likedProduct",
        JSON.stringify([...state.likedProduct, action.data])
      );
      return {
        ...state,
        likedProduct: [...state.likedProduct, action.data],
      };
    case "REMOVE_FROM_LIKED_PRODUCT":
      const item = state.likedProduct.filter(
        (product) => product.id !== action.productId
      );
      localStorage.setItem("likedProduct", JSON.stringify(item));
      return {
        ...state,
        likedProduct: item,
      };

    default:
      return state;
  }
}

export default reducer;
