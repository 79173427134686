import React, { useState } from "react";

import { ReactComponent as EyeIcon } from "../../../../Assets/icons/eye.svg";
import { ReactComponent as EyeCloseIcon } from "../../../../Assets/icons/eye-off.svg";
import { ReactComponent as CheckMark } from "../../../../Assets/icons/correct.svg";
import { ReactComponent as FacebookIcon } from "../../../../Assets/icons/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../../../Assets/icons/google.svg";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../../../StateProvider";
import { googleLogin, login } from "../../../../apiCall";
import toast from "react-hot-toast";
import { useGoogleLogin } from "@react-oauth/google";
import { useQueryClient } from "react-query";

const Login = () => {
    const [, dispatch] = useStateValue();
    const [showPassword, setShowPassword] = useState();
    const queryClient = useQueryClient();

    const navigateTo = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const doGoogleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            console.log(tokenResponse, "token response");
            verifyGoogleUser(tokenResponse);
        },
        onError: (error) => console.log("error", error),
    });
    const verifyGoogleUser = async (data) => {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await googleLogin(data.access_token);
        dispatch({ type: "SET_IS_LOADING", status: false });
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                // setError("general", {type: "general", message: re.data.status[0].ResponseMessage})
                toast.error(re.data.status[0].ResponseMessage);
                return;
            } else {
                localStorage.setItem("token", re.data.access_token);
                localStorage.setItem("refresh_token", re.data.refresh_token);
                localStorage.setItem("email", re.data.value?.email);
                localStorage.setItem(
                    "phone_number",
                    re.data.value?.phoneNumber
                );
                localStorage.setItem("firstName", re.data.value?.firstName);
                localStorage.setItem("lastName", re.data.value?.lastName);

                dispatch({
                    type: "SET_USER_LOGGED_IN",
                    status: true,
                });

                if (!re.data.value?.phoneNumber) {
                    navigateTo("/get-details");
                }else{
                    navigateTo("/")
                }
            }
        }
    };

    const verifyUser = async (data) => {
        dispatch({ type: "SET_LOADING", status: true });
        try {
            const response = await login(data);
            console.log(response);
            if (response.status === 200) {
                dispatch({ type: "SET_LOGIN_STATUS", status: true });
                    localStorage.setItem("token", response.data.access_token);
                    localStorage.setItem(
                        "refresh_token",
                        response.data.refresh_token
                    );
                    localStorage.setItem("email", response.data.value?.email);
                    localStorage.setItem(
                        "phone_number",
                        response.data.value?.phoneNumber
                    );
                    localStorage.setItem("firstName", response.data.value?.firstName);
                    localStorage.setItem("lastName", response.data.value?.lastName);
                    localStorage.setItem("coins", response?.data?.value?.coins)

                dispatch({
                    type: "SET_USER_DETAIL",
                    detail: response.data.value,
                });
                dispatch({ type: "SET_USER_LOGGED_IN", status: true });
                toast.success(response?.data?.status[0]?.ResponseMessage);
                queryClient.invalidateQueries(["cart-details"]);

                navigateTo("/");
            } else {
                toast.error(
                    response?.response?.data?.status[0]?.ResponseMessage
                );
            }
        } catch (err) {
            console.log(err, "error response");
            toast.error("Something went wrong");
        }
        dispatch({ type: "SET_LOADING", status: false });
    };

    return (
        <div className='login-main'>
            <div className='login-container'>
                <h3>Log in</h3>
                <p className='desc'>
                If you don't have account already then please proceed with <span onClick={() => navigateTo("/signup")}>sign-up</span>
                </p>

                <form
                    className='my-form'
                    onSubmit={handleSubmit((data) => verifyUser(data))}>
                    <div className='input-grp'>
                        <input
                            type='email'
                            placeholder='E-mail'
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message: "Enter a valid email",
                                },
                            })}
                        />
                    </div>
                    {errors?.email && (
                        <div className='form-error'>
                            {errors?.email?.message}
                        </div>
                    )}
                    <div className='input-grp'>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder='Password'
                            {...register("password", {
                                required: "Password is required",
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,16}$/i,
                                    message:
                                        "Password should be atleast 6 and maximum 16 characters and must contain uppercase, lowercase, numbers and special characters",
                                },
                            })}
                        />
                        {showPassword ? (
                            <EyeIcon
                                onClick={() => setShowPassword((pre) => !pre)}
                            />
                        ) : (
                            <EyeCloseIcon
                                onClick={() => setShowPassword((pre) => !pre)}
                            />
                        )}
                    </div>
                    {errors?.password && (
                        <div className='form-error'>
                            {errors?.password?.message}
                        </div>
                    )}

                    <div className='remember-forgot'>
                        {/* <div
                            className='remember'
                            onClick={() => setRememberMe((pre) => !pre)}>
                            <span className='check-box'>
                                {rememberMe && <CheckMark />}
                            </span>
                            <p>Keep me signed in</p>
                        </div> */}
                        <p onClick={() => navigateTo("/forgot-password")}>
                            Forgot Password?
                        </p>
                    </div>
                    <div className='buttons'>
                        <button className='primary-btn-black'>Sign in</button>
                        <span>
                            <hr />
                            <p>OR</p>
                            <hr />
                        </span>
                        <div className='socials'>
                            {/* <div className='facebook social-btn'>
                                <FacebookIcon />
                                <p>Facebook</p>
                            </div> */}
                            <div
                                className='google social-btn'
                                onClick={doGoogleLogin}>
                                <GoogleIcon />
                                <p>Google</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
