import React, { useEffect, useState } from "react";

import { ReactComponent as LogoutIcon } from "../../Assets/icons/logout.svg";
import { ReactComponent as TickIcon } from "../../Assets/icons/outline-tick.svg";
import { ReactComponent as EditIcon } from "../../Assets/icons/edit-outline.svg";
import "./profile.scss";

import AddressSelector from "../../Components/AddressSelector/AddressSelector";
import { useNavigate } from "react-router-dom";
import {
    CancelOrderModel,
    OrderDetailsModel,
    ReplaceOrderModel,
} from "../../Modals";
import { useStateValue } from "../../StateProvider";
import { GenerateRefreshToken, GetOrder, updateProfile } from "../../apiCall";
import toast from "react-hot-toast";
import { domainName } from "../../Constants";

const Profile = () => {
    const navigateTo = useNavigate();
    const [orderDetailsId, setOrderDetailsId] = useState();
    const [products, setProducts] = useState([]);
    const [productsImages, setProductsImages] = useState([]);
    const [firstName, setFirstName] = useState(
        localStorage.getItem("firstName") || sessionStorage.getItem("firstName")
    );
    const [lastName, setLastName] = useState(
        localStorage.getItem("lastName") || sessionStorage.getItem("lastName")
    );
    const [phoneNumber, setPhoneNumber] = useState(
        localStorage.getItem("phone_number") ||
            sessionStorage.getItem("phone_number")
    );
    const [email, setEmail] = useState(
        localStorage.getItem("email") || sessionStorage.getItem("email")
    );

    const [editItem, setEditItem] = useState("");

    const [ {showOrderDetailsModel, showReplaceOrderModel, showCancelOrderModel}, dispatch] = useStateValue();
    const setOrderDetailsModelShow = (order_id) => {
        setOrderDetailsId(order_id);
        dispatch({
            type: "SET_ORDER_DETAILS_MODAL",
            status: true,
        });
    };
    const setReplaceOrderModelShow = (order_id) => {
        setOrderDetailsId(order_id);
        dispatch({
            type: "SET_REPLACE_ORDER_MODAL",
            status: true,
        });
    };
    const setCancelOrderModelShow = (order_id) => {
        setOrderDetailsId(order_id);
        dispatch({
            type: "SET_CANCEL_ORDER_MODAL",
            status: true,
        });
    };
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch({ type: "SET_USER_LOGGED_IN", status: false });
        navigateTo("/");
    };
    const handleSave = async (type) => {
        let my_data = {
            first_name:
                localStorage.getItem("firstName") ||
                sessionStorage.getItem("firstName"),
            last_name:
                localStorage.getItem("lastName") ||
                sessionStorage.getItem("lastName"),
            email:
                localStorage.getItem("email") ||
                sessionStorage.getItem("email"),
            phone_number:
                localStorage.getItem("phone_number") ||
                sessionStorage.getItem("phone_number"),
        };

        if (type === "firstname") {
            my_data["first_name"] = firstName;
        } else if (type === "lastname") {
            my_data["last_name"] = lastName;
        } else if (type === "phonenumber") {
            my_data["phone_number"] = phoneNumber;
        } else {
            my_data["email"] = email;
        }

        dispatch({ type: "SET_LOADING", status: true });
        const updateResponse = await updateProfile({
            ...my_data,
            gender: "",
            DOB: "",
        });
        dispatch({ type: "SET_LOADING", status: false });
        if (updateResponse.status === 401) {
            dispatch({ type: "SET_LOADING", status: true });
            const refreshRe = await GenerateRefreshToken();
            dispatch({ type: "SET_LOADING", status: false });

            console.log(refreshRe, "REFRESH RE");

            if (refreshRe?.data?.access_token) {
                localStorage.setItem(
                    "refresh_token",
                    refreshRe?.data?.refresh_token
                );
                localStorage.setItem("token", refreshRe?.data?.access_token);
                handleSave(type);
            } else {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.clear();
                sessionStorage.clear();
                navigateTo("/login");
            }
        } else if (updateResponse.status === 200) {
            setEditItem("");
            toast.success(type + " updated successfully.");
            localStorage.setItem("firstName", my_data["first_name"]);
            localStorage.setItem("lastName", my_data["last_name"]);
            localStorage.setItem("email", my_data["email"]);
            localStorage.setItem("phone_number", my_data["phone_number"]);
            localStorage.setItem("token", updateResponse.data.access_token);
            localStorage.setItem(
                "refresh_token",
                updateResponse.data.refresh_token
            );
        } else {
            toast.error(
                updateResponse?.response?.data?.status[0]?.ResponseMessage
            );
        }
    };
    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_LOADING", status: true });
            const Re = await GetOrder();
            dispatch({ type: "SET_LOADING", status: false });
            console.log(Re);

            if (Re.status === 401) {
                dispatch({ type: "SET_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    getData();
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (Re?.status === 200) {
                console.log(Re);

                let my_products = [];

                Re?.data?.value?.forEach((order) => {
                    order?.product?.forEach((product) => {
                        my_products.push({
                            ...product,
                            order_id: order?.id,
                            uuid: order?.uuid,
                            status: order?.status,
                            create_at: order?.create_at,
                        });
                    });
                });

                setProducts(my_products);
                setProductsImages(my_products);
            } else if (Re?.response?.status === 401) {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.removeItem("token");
                localStorage.removeItem("user_details");
                localStorage.removeItem("cartItem");
                navigateTo("/login");
            } else {
                if (Re?.response?.data?.status) {
                    toast.error(Re?.response?.data?.status[0].ResponseMessage);
                } else {
                    toast.error(Re?.response?.data?.detail);
                }
            }
        };

        getData();
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    useEffect(() => {
        products?.forEach((prod) => {
            console.log("EACH PRODUCT", prod);
            const xhr = new XMLHttpRequest();
            xhr.open(
                "GET",
                domainName + "/uploads/" + prod?.productDetail?.main_image,
                true
            );
            xhr.responseType = "blob";
            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = Math.round(
                        (event.loaded / event.total) * 100
                    );
                    // setProgress(percentComplete);
                }
            };
            xhr.onload = () => {
                const url = URL.createObjectURL(xhr.response);
                // displayProducts.push({ ...prod, main_image: url, loaded: true });

                console.log(url);
                setProductsImages((pre) => {
                    const my_list = [];
                    pre.forEach((myProd) => {
                        if (
                            myProd?.productDetail?.id ===
                            prod?.productDetail?.id
                        ) {
                            my_list.push({
                                ...myProd,
                                productDetail: {
                                    ...myProd?.productDetail,
                                    main_image: url,
                                },
                            });
                        } else {
                            my_list.push(myProd);
                        }
                    });

                    return my_list;
                });
            };
            xhr.send();
        });
    }, [products]);
    return (
        <div className='profile-main'>
            <div className='header'>
                <h3>
                    HII,{" "}
                    {sessionStorage.getItem("firstName") ||
                        localStorage.getItem("firstName")}
                </h3>
                <div className='buttons'>
                    {/* <div className='option'>
                        <span className='icon'>
                        <NotificationIcon />
                        </span>
                        <p>ON</p>
                        </div> */}
                    <button className='option logout' onClick={handleLogout}>
                        LOGOUT <LogoutIcon />
                    </button>
                </div>
            </div>
            <hr />
            <div className='details-container'>
                <div className='address-container'>
                    <div className='profile-editor'>
                        <div className='my-grp'>
                            <label htmlFor='name'>First Name</label>
                            <div
                                className={`input-grp ${
                                    editItem === "firstname" ? "selected" : ""
                                }`}>
                                <input
                                    type='text'
                                    name=''
                                    id=''
                                    value={firstName}
                                    disabled={
                                        editItem === "firstname" ? false : true
                                    }
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                                {editItem === "firstname" ? (
                                    <button
                                        className='my-btn'
                                        onClick={() => handleSave("firstname")}>
                                        Save <TickIcon />
                                    </button>
                                ) : (
                                    <span
                                        onClick={() =>
                                            setEditItem("firstname")
                                        }>
                                        Edit <EditIcon />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='my-grp'>
                            <label htmlFor='name'>Last Name</label>
                            <div
                                className={`input-grp ${
                                    editItem === "lastname" ? "selected" : ""
                                }`}>
                                <input
                                    type='text'
                                    name=''
                                    id=''
                                    value={lastName}
                                    disabled={
                                        editItem === "lastname" ? false : true
                                    }
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                />
                                {editItem === "lastname" ? (
                                    <button
                                        className='my-btn'
                                        onClick={() => handleSave("lastname")}>
                                        Save <TickIcon />
                                    </button>
                                ) : (
                                    <span
                                        onClick={() => setEditItem("lastname")}>
                                        Edit <EditIcon />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='my-grp'>
                            <label htmlFor='name'>Mobile Number</label>
                            <div
                                className={`input-grp ${
                                    editItem === "phonenumber" ? "selected" : ""
                                }`}>
                                <input
                                    type='number'
                                    name=''
                                    id=''
                                    value={phoneNumber}
                                    disabled={
                                        editItem === "phonenumber"
                                            ? false
                                            : true
                                    }
                                    onChange={(e) =>
                                        setPhoneNumber(e.target.value)
                                    }
                                />
                                {editItem === "phonenumber" ? (
                                    <button
                                        className='my-btn'
                                        onClick={() =>
                                            handleSave("phonenumber")
                                        }>
                                        Save <TickIcon />
                                    </button>
                                ) : (
                                    <span
                                        onClick={() =>
                                            setEditItem("phonenumber")
                                        }>
                                        Edit <EditIcon />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='my-grp'>
                            <label htmlFor='name'>Email</label>
                            <div
                                className={`input-grp ${
                                    editItem === "email" ? "selected" : ""
                                }`}>
                                <input
                                    type='email'
                                    name=''
                                    id=''
                                    disabled={
                                        editItem === "email" ? false : true
                                    }
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {editItem === "email" ? (
                                    <button
                                        className='my-btn'
                                        onClick={() => handleSave("email")}>
                                        Save <TickIcon />
                                    </button>
                                ) : (
                                    <span onClick={() => setEditItem("email")}>
                                        Edit <EditIcon />
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <AddressSelector fullview={true} />
                </div>
                <div className='order-details'>
                    <div className='head'>
                        <p className='title'>Order History</p>
                        <p
                            className='policy'
                            onClick={() => navigateTo("/shipping-policy")}>
                            Shipping Policy
                        </p>
                    </div>
                    <div className='product-items'>
                        {productsImages?.map((item) => (
                            <div
                                className='item'
                                key={"item-" + item?.productDetail?.id}>
                                <div className='details'>
                                    {item?.productDetail?.main_image?.includes(
                                        "blob:"
                                    ) ? (
                                        <img
                                            src={
                                                item?.productDetail?.main_image
                                            }
                                            alt=''
                                        />
                                    ) : (
                                        <div className='loading-statistics-container'>
                                            <div className='component-loading statistics-loading-cmp'></div>
                                            <div className='component-loading statistics-loading-cmp'></div>
                                            <div className='component-loading statistics-loading-cmp'></div>
                                        </div>
                                    )}
                                    <div className='my-text'>
                                        <div className='name'>
                                            <p className='bold'>
                                                {item?.productDetail?.title}
                                            </p>
                                            <p>{new Date(item?.create_at)?.getDate() + "/" + (new Date(item?.create_at)?.getMonth() + 1) + "/" + new Date(item?.create_at)?.getFullYear()+" - "+ new Date(item?.create_at)?.getHours()+":"+new Date(item?.create_at)?.getMinutes()}</p>
                                            <p>#{item?.uuid}</p>
                                        </div>
                                        {/* <p className='detail'>{item?.productDetail?.color}</p> */}
                                        <p className='detail'>{item?.size}</p>
                                    </div>
                                    <p className='detail'>x{item?.quantity}</p>
                                    <p className='detail price'>
                                        {item?.productDetail?.price.toLocaleString(
                                            "en-US",
                                            {
                                                style: "currency",
                                                currency: "INR",
                                            }
                                        )}
                                    </p>
                                </div>
                                <div className='buttons'>
                                    <button
                                        className='my-btn'
                                        onClick={() =>
                                            setOrderDetailsModelShow(
                                                item?.order_id
                                            )
                                        }>
                                        ORDER DETAILS
                                    </button>
                                    {item?.status === "Completed" && (
                                        <button
                                            className='my-btn'
                                            onClick={() =>
                                                setReplaceOrderModelShow(
                                                    item?.order_id
                                                )
                                            }>
                                            REPLACE ORDER
                                        </button>
                                    )}
                                    {item?.status === "Received" && (
                                        <button
                                            className='my-btn'
                                            onClick={() =>
                                                setCancelOrderModelShow(
                                                    item?.order_id
                                                )
                                            }>
                                            CANCEL ORDER
                                        </button>
                                    )}
                                    {item?.status !== "Received" &&
                                        item?.status !== "Completed" && (
                                            <span className='my-btn label'>
                                                {"Order " + item?.status}
                                            </span>
                                        )}
                                    <button
                                        className={`${
                                            "status" == "status"
                                                ? "primary-btn-black"
                                                : "my-btn disable"
                                        }`}>
                                        TRACK ORDER
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {orderDetailsId && <OrderDetailsModel order_id={orderDetailsId} />}
            {orderDetailsId && <ReplaceOrderModel order_id={orderDetailsId} />}
            {orderDetailsId && <CancelOrderModel order_id={orderDetailsId} />}
        </div>
    );
};

export default Profile;
