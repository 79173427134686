import React, { useEffect } from "react";

import "./orderSuccess.scss";
import SuccessImage from "../../Assets/icons/success.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useStateValue } from "../../StateProvider";

const OrderSuccess = () => {
    const navigateTo = useNavigate();
    const queryClient = useQueryClient();
    const [, dispatch] = useStateValue();
    const handleRefetch = () => {
        queryClient.invalidateQueries(["cart-details"]);
    };

    useEffect(() => {
        dispatch({
            type: "RESET_CART",
            cartData: [],
        });
        handleRefetch();
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className='order-success-main'>
            <h1>ALL DONE!</h1>
            <img src={SuccessImage} alt='' />
            <p>
                {"Order placed successfully with order id: "+searchParams.get("orderId")}
            </p>
            <button
                className='primary-btn-black'
                onClick={() => navigateTo("/")}>
                GREAT! THANKS
            </button>
        </div>
    );
};

export default OrderSuccess;
