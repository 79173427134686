import { toast } from 'react-hot-toast'
import { AddToWatchList } from '../apiCall'

export async function AddToFavorites(product, dispatch, navigateTo=undefined) {
    const isLogin = localStorage.getItem("token") ? true : false
    if(isLogin){

        const re = await AddToWatchList(product.id)
        if (re?.status === 200) {
            dispatch({ type: "ADD_TO_LIKED_PRODUCT", data: product })
        }
        else if (re?.status === 208) {
            toast.error(re?.data?.status[0].ResponseMessage)
        }
        else{
            toast.error(re?.response?.data?.status[0].ResponseMessage)
        }
    }
    else if(navigateTo){
        navigateTo("/login")
    }

}

