import React, { useEffect, useState } from "react";
import SelectAddress from "./SelectAddress";

import "./addressSelector.scss";
import Address from "./Address";
import { useQuery } from "react-query";
import { GenerateRefreshToken, GetAddress } from "../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../StateProvider";
import { useNavigate } from "react-router-dom";

// const addresses = [
//   {
//     id: 1,
//     firstname: "Prit",
//     lastname: "Manvar",
//     address: "139, Vikaram nagar 2",
//     pincode: 395010,
//     state: "Gujarat",
//     city: "Surat",
//     phonenumber: 8238378605,
//     email: "pritmanvar@aapkacfo.com",
//   },
//   {
//     id: 2,
//     firstname: "Prit",
//     lastname: "Manvar",
//     address: "139, Vikaram nagar 2",
//     pincode: 395010,
//     state: "Gujarat",
//     city: "Surat",
//     phonenumber: 8238378605,
//     email: "pritmanvar@aapkacfo.com",
//   },
//   {
//     id: 2,
//     firstname: "Prit",
//     lastname: "Manvar",
//     address: "139, Vikaram nagar 2",
//     pincode: 395010,
//     state: "Gujarat",
//     city: "Surat",
//     phonenumber: 8238378605,
//     email: "pritmanvar@aapkacfo.com",
//   },
//   {
//     id: 2,
//     firstname: "Prit",
//     lastname: "Manvar",
//     address: "139, Vikaram nagar 2",
//     pincode: 395010,
//     state: "Gujarat",
//     city: "Surat",
//     phonenumber: 8238378605,
//     email: "pritmanvar@aapkacfo.com",
//   },
//   {
//     id: 2,
//     firstname: "Prit",
//     lastname: "Manvar",
//     address: "139, Vikaram nagar 2",
//     pincode: 395010,
//     state: "Gujarat",
//     city: "Surat",
//     phonenumber: 8238378605,
//     email: "pritmanvar@aapkacfo.com",
//   },
// ];
const AddressSelector = ({ fullview,setSelectedAddress,selectedAddress }) => {
  const [currentSection, setCurrentSection] = useState("selectAddress");
  const [updateAddressData, setUpdateAddressData] = useState({});
  const [{ cartItem }, dispatch] = useStateValue();
  const navigateTo = useNavigate();

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["address"],
    queryFn: async () => {
      dispatch({ type: "SET_IS_LOADING", status: true });
      const Re = await GetAddress();
      dispatch({ type: "SET_IS_LOADING", status: false });
      
      if (Re.status === 401) {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const refreshRe = await GenerateRefreshToken();
        dispatch({ type: "SET_IS_LOADING", status: false });

        console.log(refreshRe, "REFRESH RE");

        if (refreshRe?.data?.access_token) {
            localStorage.setItem(
                "refresh_token",
                refreshRe?.data?.refresh_token
            );
            localStorage.setItem(
                "token",
                refreshRe?.data?.access_token
            );
            refetch()
        } else {
            dispatch({ type: "SET_USER_LOGGED_IN", status: false });
            dispatch({ type: "SET_CART_ITEM", status: [] });
            dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
            dispatch({ type: "SET_USER_DETAIL", detail: {} });
            localStorage.clear();
            sessionStorage.clear();
            navigateTo("/login");
        }
    } else if (Re?.status === 200) {
        setAddresses(Re.data.value);
      }
      else if (Re?.response?.status === 401) {
        dispatch({type:"SET_USER_LOGGED_IN",status:false})
        dispatch({type:"SET_CART_ITEM",status:[]})
        dispatch({type:"SET_LIKED_PRODUCT",status:[]})
        dispatch({type:"SET_USER_DETAIL",detail:{}})
        localStorage.removeItem("token")
        localStorage.removeItem("user_details")
        localStorage.removeItem("cartItem")
        navigateTo("/login")
      } 
       else {
        toast.error(Re?.response.data?.status[0].ResponseMessage);
      }
    },
  });
  const [addresses, setAddresses] = useState(
    data?.status === 200 ? data.data.value : []
  );
  console.log(updateAddressData)

  useEffect(() => {
    if(!addresses.length){
      setCurrentSection("addAddress")
    }else{
      setCurrentSection("selectAddress")
    }
  }, [addresses])
  return (
    <div className={`address-selector-main ${fullview ? "full-view" : ""}`}>
      {currentSection === "addAddress" && (
        <Address
          setCurrentSection={setCurrentSection}
          updateAddressData={updateAddressData}
          refetch={refetch}
          setUpdateAddressData={setUpdateAddressData}

        />
      )}
      {currentSection === "updateAddress" && (
        <Address
          setCurrentSection={setCurrentSection}
          updateAddressData={updateAddressData}
          refetch={refetch}
          setUpdateAddressData={setUpdateAddressData}

        />
      )}
      {currentSection === "selectAddress" && (
        <SelectAddress
          addresses={addresses}
          setCurrentSection={setCurrentSection}
          setUpdateAddressData={setUpdateAddressData}
          refetch={refetch}
          setSelectedAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
        />
      )}
    </div>
  );
};

export default AddressSelector;
