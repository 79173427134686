import React from "react";

import image from "../../../../Assets/images/img10.svg";
import "./googleDetails.scss";
import { useForm } from "react-hook-form";
import { sendOtp } from "../../../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../../../StateProvider";

const GoogleDetails = ({ setCurrentComponent, setData }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    
    const [, dispatch] = useStateValue();

    const getOtherDetails = async (data) => {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const response = await sendOtp({ phone_number: data?.phone_number });
        dispatch({ type: "SET_IS_LOADING", status: false });
        console.log(response)
        if (response.status === 200) {
            setData(data);
            setCurrentComponent("otp");
        } else {
            toast.error(response?.response?.data?.status[0]?.ResponseMessage);
        }
    };

    return (
        <div className='google-login-main'>
            <div className='my-container'>
                <div className='google-login-container'>
                    <h3>Complete Profile</h3>
                    <p className='desc'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                    </p>

                    <div className='my-form'>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type='text'
                                    placeholder='First Name'
                                    {...register("first_name", {
                                        required: true,
                                    })}
                                />
                            </div>
                            {errors.first_name?.type === "required" && (
                                <p className='form-error'>
                                    First name is required.
                                </p>
                            )}
                        </div>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type='text'
                                    placeholder='last Name'
                                    {...register("last_name", {
                                        required: true,
                                    })}
                                />
                            </div>
                            {errors.last_name?.type === "required" && (
                                <p className='form-error'>
                                    Last name is required.
                                </p>
                            )}
                        </div>
                        <div className='my-input'>
                            <div className='input-grp'>
                                <input
                                    type='number'
                                    placeholder='Phone number'
                                    {...register("phone_number", {
                                        required: true,
                                    })}
                                />
                            </div>
                            {errors.phone_number?.type === "required" && (
                                <p className='form-error'>
                                    Phone number is required.
                                </p>
                            )}
                        </div>
                        <div className='buttons'>
                            <button
                                className='primary-btn-black'
                                onClick={handleSubmit(getOtherDetails)}>
                                Save Details
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <img src={image} className='main-image' alt='' />
        </div>
    );
};

export default GoogleDetails;
