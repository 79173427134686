import React, { useEffect, useRef, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { RxCross1 } from "react-icons/rx";
import "./searchInput.scss";
import { useQuery } from "react-query";
import { GetProducts } from "../../../apiCall";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useStateValue } from "../../../StateProvider";

function SearchInput({ handleSearchClose, handleClose }) {
    const [, dispatch] = useStateValue()
    const navigateTo = useNavigate();


    const [items, setItems] = useState();
    const navigate = useNavigate();

    useEffect(() => {
      const getData = async () => {
        const Re = await GetProducts("", "");
        console.log(Re)
        if (Re?.status === 200) {
            setItems(
                Re.data.value?.map((prod) => {
                    return { ...prod, name: prod?.title };
                })
            );
        } 
        
        else if (Re?.response?.status === 401) {
            
            dispatch({type:"SET_USER_LOGGED_IN",status:false})
            dispatch({type:"SET_CART_ITEM",status:[]})
            dispatch({type:"SET_LIKED_PRODUCT",status:[]})
            dispatch({type:"SET_USER_DETAIL",detail:{}})
            localStorage.removeItem("token")
            localStorage.removeItem("user_details")
            localStorage.removeItem("cartItem")
            navigateTo("/login")
          } 
          else {
            if (Re?.data?.status) {
                toast.error(Re?.data?.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try agian.");
            }
        }
      }
      getData()
    }, [])

    const handleOnSelect = (item) => {
        // the item selected
        handleSearchClose()
        handleClose()
        navigate(`/product-details/${item.id}`);
    };

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: "block", textAlign: "left" }}>
                    {item.name}
                </span>
            </>
        );
    };
    return (
        <section className='search-input'>
            <ReactSearchAutocomplete
                items={items}
                onSelect={handleOnSelect}
                autoFocus={false}
                formatResult={formatResult}
                placeholder='Find products..'
            />
            <div className='cross-search'>
                <RxCross1 onClick={() => {handleSearchClose(); handleClose()}} />
            </div>
        </section>
    );
}

export default SearchInput;
