import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./orderFail.scss";
import FailImage from "../../Assets/icons/fail.png";

const OrderFail = () => {
    const navigateTo = useNavigate();
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
   {searchParams.get("message")}
  return (
    <div className='order-fail-main'>
      <h1>ALL DONE!</h1>
            <img src={FailImage} alt='' />
            <p>
            {searchParams.get("message")}
            </p>
            <button
                className='primary-btn-black'
                onClick={() => navigateTo("/")}>
                OOPS! Try Again
            </button>
    </div>
  )
}

export default OrderFail
