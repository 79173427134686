import React from "react";

import "./selectAddress.scss";
import { ReactComponent as CheckMark } from "../../Assets/icons/correct.svg";
import { GenerateRefreshToken, RemoveAddress } from "../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../StateProvider";
import { useNavigate } from "react-router-dom";

const SelectAddress = ({
    addresses,
    setCurrentSection,
    setUpdateAddressData,
    refetch,
    setSelectedAddress,
    selectedAddress,
}) => {
    const [, dispatch] = useStateValue()
    const navigateTo = useNavigate()
    const openAddressEditor = (status) => {
        setCurrentSection(status);
    };
    const removeAddress = async (id) => {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await RemoveAddress(id);
        dispatch({ type: "SET_IS_LOADING", status: false });
        console.log(re);
        if (re.status === 401) {
            dispatch({ type: "SET_IS_LOADING", status: true });
            const refreshRe = await GenerateRefreshToken();
            dispatch({ type: "SET_IS_LOADING", status: false });

            console.log(refreshRe, "REFRESH RE");

            if (refreshRe?.data?.access_token) {
                localStorage.setItem(
                    "refresh_token",
                    refreshRe?.data?.refresh_token
                );
                localStorage.setItem("token", refreshRe?.data?.access_token);
                removeAddress(id);
            } else {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.clear();
                sessionStorage.clear();
                navigateTo("/login");
            }
        } else if (re.status === 200) {
            refetch();
            setUpdateAddressData({});
            toast.success(re.data.status[0].ResponseMessage);
        } else {
            toast.error(re.resaponse.data.status[0].ResponseMessage);
        }
    };
    return (
        <div className='select-address'>
            {addresses.length > 0 ? (
                <div className='address-list'>
                    {addresses?.map((address) => (
                        <div className='address'>
                            <div className='address-details'>
                                <span
                                    className='check-box'
                                    onClick={() => setSelectedAddress(address)}>
                                    {selectedAddress?.id === address?.id ? (
                                        <CheckMark />
                                    ) : (
                                        ""
                                    )}
                                </span>
                                <div className='details'>
                                    <p className='bold'>{`${address?.first_name} ${address?.last_name}`}</p>
                                    <p>
                                        {address?.address}, {address?.city},{" "}
                                        {address?.state}
                                    </p>
                                    <p>{address?.phonenumber}</p>
                                    <p>{address?.email}</p>
                                </div>
                            </div>
                            <div className='links'>
                                <p
                                    className='update'
                                    onClick={() => {
                                        setUpdateAddressData(address);
                                        openAddressEditor("updateAddress");
                                    }}>
                                    Update
                                </p>
                                <p
                                    className='delete'
                                    onClick={() => removeAddress(address.id)}>
                                    Delete
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <h4
                    style={{
                        minHeight: "20vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    No address found.
                </h4>
            )}
            <span
                className='add-address'
                onClick={() => openAddressEditor("addAddress")}>
                Add Address
            </span>
        </div>
    );
};

export default SelectAddress;
