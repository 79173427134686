import React, { useState } from "react";

import "./forgotPassword.scss";
import { ResetPassword, SendOtp, VerifyOtp } from "../../../../apiCall";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../../StateProvider";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [otpVerified, setOtpVerifies] = useState(false)
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [passwordMismatchError, setPasswordMismatchError] = useState("");
    const [token, setToken] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const navigateTo = useNavigate()
    const [, dispatch] = useStateValue();

    const sendOtp = async () => {
        if (!email) {
            setEmailError("Email is required");
            return;
        } else {
            setEmailError("");
        }
        dispatch({ type: "SET_IS_LOADING", status: true });
        const Re = await SendOtp({ email: email, reason: "Forgot Password" });
        dispatch({ type: "SET_IS_LOADING", status: false });
        console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            toast.success("OTP Sent successfully.");
            setIsOtpSent(true)
            setEmailError("")
            sessionStorage.setItem("reset_pass_token", Re.data.token)
        } else if (Re?.data?.status) {
            toast.error(Re.data.status[0].ResponseMessage);
        } else {
            toast.error("Something went wrong, Please try again.");
        }
    };
    const verifyOtp = async () => {
        // e.preventDefault();
        if(!isOtpSent){
            setEmailError("Please send otp first..")
            return;
        }
        if (!otp) {
            setOtpError("Otp is required");
            return;
        } else {
            setOtpError("");
        }

        dispatch({ type: "SET_IS_LOADING", status: true });
        const Re = await VerifyOtp(otp);
        dispatch({ type: "SET_IS_LOADING", status: false });
        console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            setToken(Re.data.value);
            setOtpVerifies(true)
            toast.success("OTP Verified, Please enter your new password.");
            setEmailError("")
            setOtpError("")
        } else if (Re?.response?.data?.status) {
            toast.error(Re?.response.data.status[0].ResponseMessage);
        } else {
            toast.error("Something went wrong, Please try again.");
        }
    };
    const changePassword = async (data) => {
        if(!email){
            setEmailError("Email is required.")
            return;
        }
        if(!otpVerified){
            setOtpError("Please verify your otp first!!!")
            return;
        }
        if (data.newPassword !== data.confirm_password) {
            setPasswordMismatchError(
                "Password and Confirm password must be same."
            );
            return;
        } else {
            setPasswordMismatchError("");
        }

        dispatch({ type: "SET_IS_LOADING", status: true });
        const Re = await ResetPassword({
            email: email,
            newPassword: data.newPassword,
            token: token,
        });
        dispatch({ type: "SET_IS_LOADING", status: false });
        // console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            toast.success("Password Changed!!");
            navigateTo("/login");
        } else if (Re?.data?.status) {
            toast.error(Re.data.status[0].ResponseMessage);
        } else {
            toast.error("Something went wrong, Please try again.");
        }
    };
    return (
        <div className='forgot-password-main'>
            <div className='forgot-password-container'>
                <h3>Forgot Password.</h3>

                <div className='my-form'>
                    <div>
                        <div className='input-grp'>
                            <input
                                type='email'
                                placeholder='E-mail'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className='primary-btn-white-outline' onClick={() => sendOtp()}>
                                Send OTP
                            </button>
                        </div>
                        <p className='form-error'>{emailError}</p>
                    </div>
                    <div>
                        <div className='input-grp'>
                            <input
                                type='text'
                                placeholder='OTP'
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <button className='primary-btn-white-outline' onClick={() => verifyOtp()}>
                                Verify OTP
                            </button>
                        </div>
                        <p className='form-error'>{otpError}</p>
                    </div>
                    <div>
                        <div className='input-grp'>
                            <input
                                type={"password"}
                                placeholder='Password'
                                {...register("newPassword", {
                                    required: true,
                                    pattern:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                                })}
                            />
                        </div>
                        {errors.newPassword?.type === "required" && (
                            <p className='form-error'>Password is required.</p>
                        )}
                        {errors.newPassword?.type === "pattern" && (
                            <p className='form-error'>
                                Must Be Contains Uppercase, Lowercase Letters,
                                Numbers, Special Characters And Length Is
                                Greater Than 8 Character And Less Then 16
                                Character.
                            </p>
                        )}
                    </div>
                    <div>
                        <div className='input-grp'>
                            <input
                                type={"password"}
                                placeholder='Confirm Password'
                                {...register("confirm_password", {
                                    required: true,
                                    pattern:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                                })}
                            />
                        </div>
                        {errors.confirm_password?.type === "required" && (
                            <p className='form-error'>
                                Confirm password is required.
                            </p>
                        )}
                        {errors.confirm_password?.type === "pattern" && (
                            <p className='form-error'>
                                Must Be Contains Uppercase, Lowercase Letters,
                                Numbers, Special Characters And Length Is
                                Greater Than 8 Character And Less Then 16
                                Character.
                            </p>
                        )}
                        <p className='form-error'>{passwordMismatchError}</p>
                    </div>

                    <div className='buttons'>
                        <button className='primary-btn-black' onClick={handleSubmit(changePassword)}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
