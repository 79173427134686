import React from 'react'
import Modal from "react-bootstrap/Modal";
import { useStateValue } from "./StateProvider";

import './modals.scss'
import OrderDetails from './Components/OrderDetails/OrderDetails';
import ReplaceOrder from './Components/ReplaceOrder/ReplaceOrder';
import CancelOrder from './Components/CancelOrder/CancelOrder';

export function OrderDetailsModel(props){
    const [{ showOrderDetailsModel }, dispatch] = useStateValue();
    const setOrderDetailsModelShow = (status) => {
        dispatch({
            type: "SET_ORDER_DETAILS_MODAL",
            status: status,
        });
    };
    return (
        <Modal
            {...props}
            show={showOrderDetailsModel}
            onHide={() => setOrderDetailsModelShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <OrderDetails order_id={props?.order_id} />
            </Modal.Body>
        </Modal>
    );
}

export function ReplaceOrderModel(props){
    const [{ showReplaceOrderModel }, dispatch] = useStateValue();
    const setReplaceOrderModelShow = (status) => {
        dispatch({
            type: "SET_REPLACE_ORDER_MODAL",
            status: status,
        });
    };
    return (
        <Modal
            {...props}
            show={showReplaceOrderModel}
            onHide={() => setReplaceOrderModelShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <ReplaceOrder order_id={props?.order_id} />
            </Modal.Body>
        </Modal>
    );
}

export function CancelOrderModel(props){
    const [{ showCancelOrderModel }, dispatch] = useStateValue();
    const setCancelOrderModelShow = (status) => {
        dispatch({
            type: "SET_CANCEL_ORDER_MODAL",
            status: status,
        });
    };
    return (
        <Modal
            {...props}
            show={showCancelOrderModel}
            onHide={() => setCancelOrderModelShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <CancelOrder order_id={props?.order_id} />
            </Modal.Body>
        </Modal>
    );
}