import axios from 'axios'
import { domainName, api } from '../Constants'

const client = axios.create({baseURL: domainName})

export const request = ({...options}) =>{
    let token = sessionStorage.getItem("token") || localStorage.getItem("token")
    if(options.url === api.signup){
        token = sessionStorage.getItem("signup_token") || localStorage.getItem("signup_token")
    }else if(options.url.includes(api.verify_email_otp)){
        token = sessionStorage.getItem("reset_pass_token") || localStorage.getItem("reset_pass_token")
    }

    client.defaults.headers.common.Authorization = `Bearer ${token}`

    const onSuccess = response => response
    const onError = error =>{
        return error
    }

    return client(options).then(onSuccess).catch(onError)
}