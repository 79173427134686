import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useStateValue } from "./StateProvider";

function ProtectedRoute({ children }) {
  const [{ isLoggedIn },] = useStateValue();
  let location = useLocation();
  if (!isLoggedIn && location.pathname === "/checkout") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!isLoggedIn && location.pathname === "/profile") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!isLoggedIn && location.pathname === "/order-success") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!isLoggedIn && location.pathname === "/order-fail") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const phone_number = localStorage.getItem("phone_number") || sessionStorage.getItem("phone_number")
  if(isLoggedIn && (phone_number == "0" || !phone_number) && location.pathname !== '/get-details'){
    return <Navigate to="/get-details" state={{ from: location }} replace />;
  }

  return children;
}

export default ProtectedRoute;
