import React, { useEffect, useState } from "react";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";

// import ProductImg from "../../Assets/images/img4.svg";
import { ReactComponent as CartFilled } from "../../Assets/icons/cart-filled.svg";
import { ReactComponent as MasterCard } from "../../Assets/icons/master-card-transperant.svg";
import { ReactComponent as ArrowIcon } from "../../Assets/icons/arrow.svg";
import { ReactComponent as CoinIcon } from "../../Assets/icons/coin.svg";
import { ReactComponent as CheckMark } from "../../Assets/icons/correct.svg";

import "./checkout.scss";
// import Img6 from "../../Assets/images/img6.svg";
// import Img7 from "../../Assets/images/img7.svg";
// import Img10 from "../../Assets/images/img10.svg";
// import Img11 from "../../Assets/images/img11.svg";
// import Img12 from "../../Assets/images/img12.svg";
import AddressSelector from "../../Components/AddressSelector/AddressSelector";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { domainName } from "../../Constants";
import { useQuery, useQueryClient } from "react-query";
import {
    CreateOrder,
    GenerateRefreshToken,
    GetCartTotal,
    GetCouponCodes,
    GetPopularProducts,
    RemoveFromCart,
    UpdateCart,
} from "../../apiCall";
import toast from "react-hot-toast";
import { DisplayRazorpay } from "./component/Razorpay";

const Checkout = () => {
    const [{ cartItem }, dispatch] = useStateValue();
    const navigateTo = useNavigate();
    const [hoverItem, setHoverItem] = useState();
    const [isCoinUse, setIsCoinUse] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [activeCouponCode, setActiveCouponCode] = useState("");
    const [selectedAddress, setSelectedAddress] = useState({});
    const [popularProducts, setPopularProducts] = useState([]);
    const [popularProductsImages, setPopularProductsImages] = useState([]);
    const [couponCodes, setCouponCodes] = useState([]);
    const [showCouponCodes, setShowCouponCodes] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");
    const queryClient = useQueryClient();
    const [doDonation, setDoDonation] = useState(true);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["coupon-codes"],
        queryFn: async () => {
            dispatch({ type: "SET_LOADING", status: true });
            const Re = await GetCouponCodes();
            dispatch({ type: "SET_LOADING", status: false });

            if (Re.status === 401) {
                dispatch({ type: "SET_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    refetch();
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (Re?.status === 200) {
                setCouponCodes(Re.data.value);
            } else if (Re?.response?.status === 401) {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.removeItem("token");
                localStorage.removeItem("user_details");
                localStorage.removeItem("cartItem");
                navigateTo("/login");
            } else {
                toast.error(Re?.response.data?.status[0].ResponseMessage);
            }
        },
    });

    const {
        isLoading: isCartTotalLoading,
        data: cartTotal,
        refetch: refetchCartTotla,
    } = useQuery({
        queryKey: ["cart-totle", isCoinUse, activeCouponCode],
        queryFn: async () => {
            dispatch({ type: "SET_LOADING", status: true });
            const Re = await GetCartTotal(
                isCoinUse,
                activeCouponCode,
                doDonation
            );
            dispatch({ type: "SET_LOADING", status: false });
            console.log(Re, "CART TOTAL");
            if (Re.status === 401) {
                dispatch({ type: "SET_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    refetchCartTotla();
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (Re?.status === 200) {
                setCartTotla(Re.data.value);
                if (!Re?.data?.value?.isCouponCodeApplied) {
                    setCouponCode("");
                    setActiveCouponCode("");
                }
            } else if (Re?.response?.status === 401) {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.removeItem("token");
                localStorage.removeItem("user_details");
                localStorage.removeItem("cartItem");
                navigateTo("/login");
            } else {
                toast.error(Re?.response.data?.status[0].ResponseMessage);
            }
        },
    });
    const [cartTotla, setCartTotla] = useState(
        cartTotal?.status === 200 ? cartTotal.data.value : {}
    );

    const handlePayment = async () => {
        if (selectedAddress?.id) {
            if (paymentMethod) {
                if (paymentMethod === "COD") {
                    const address = selectedAddress;
                    const orderData = {
                        coupon_code:
                            cartTotla?.coupon_code_detail?.detail?.coupon_code,
                        address_id: selectedAddress?.id,
                        payment_method: "COD",
                        payment_id: "",
                        order_id: "",
                        total_amount: cartTotla?.sub_total,
                        total_discount: cartTotla?.total_discount,
                        tax: 0,
                        membership_discount: 0,
                        referral_discount: 0,
                        referral_id: 0,
                        use_coins: isCoinUse,
                    };
                    dispatch({ type: "SET_LOADING", status: true });
                    const orderRe = await CreateOrder(orderData);
                    dispatch({ type: "SET_LOADING", status: false });
                    console.log(orderRe);
                    if (orderRe.status === 401) {
                        dispatch({ type: "SET_LOADING", status: true });
                        const refreshRe = await GenerateRefreshToken();
                        dispatch({ type: "SET_LOADING", status: false });

                        console.log(refreshRe, "REFRESH RE");

                        if (refreshRe?.data?.access_token) {
                            localStorage.setItem(
                                "refresh_token",
                                refreshRe?.data?.refresh_token
                            );
                            localStorage.setItem(
                                "token",
                                refreshRe?.data?.access_token
                            );
                            handlePayment();
                        } else {
                            dispatch({
                                type: "SET_USER_LOGGED_IN",
                                status: false,
                            });
                            dispatch({ type: "SET_CART_ITEM", status: [] });
                            dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                            dispatch({ type: "SET_USER_DETAIL", detail: {} });
                            localStorage.clear();
                            sessionStorage.clear();
                            navigateTo("/login");
                        }
                    } else if (orderRe?.status === 201) {
                        // toast.success(orderRe?.data?.status[0]?.Message);

                        queryClient.invalidateQueries(["cart-details"]);
                        if (localStorage.getItem("coins")) {
                            localStorage.setItem(
                                "coins",
                                orderRe?.data?.value?.coins
                            );
                        } else {
                            sessionStorage.setItem(
                                "coins",
                                orderRe?.data?.value?.coins
                            );
                        }
                        navigateTo(
                            `/order-success?orderId=${orderRe?.data?.value?.order_id}&message=${orderRe?.data?.status[0]?.Message}`
                        );
                    } else {
                        queryClient.invalidateQueries(["cart-details"]);
                        // toast.error(orderRe?.response.response?.data?.status[0]?.Message);
                        navigateTo(
                            `/order-fail?orderId=${orderRe?.response?.data?.value?.order_id}&message=${orderRe?.response?.data?.status[0]?.Message}`
                        );
                    }
                } else {
                    const orderData = {
                        coupon_code:
                            cartTotla?.coupon_code_detail?.detail?.coupon_code,
                        address_id: selectedAddress?.id,
                        payment_method: "Prepaid",
                        payment_id: "",
                        order_id: "",
                        total_amount: cartTotla?.sub_total,
                        total_discount: cartTotla?.total_discount,
                        tax: 0,
                        membership_discount: 0,
                        referral_discount: 0,
                        referral_id: 0,
                        use_coins: isCoinUse,
                    };

                    DisplayRazorpay(orderData, selectedAddress);
                }
            } else {
                toast("Please select payment method.", {
                    icon: "⚠️",
                    style: {
                        padding: "14px",
                        fontSize: "16px",
                        fontWeight: 600,
                    },
                });
            }
        } else {
            toast("Please select address.", {
                icon: "⚠️",
                style: {
                    padding: "14px",
                    fontSize: "16px",
                    fontWeight: 600,
                },
            });
        }
    };

    const decreaseQuantity = async (item) => {
        if (item.quantity > 1) {
            dispatch({ type: "SET_LOADING", status: true });
            const re = await UpdateCart({
                product_id: item.id,
                size_id: item.size.id,
                quantity: item.quantity - 1,
            });
            dispatch({ type: "SET_LOADING", status: false });
            console.log(re);
            if (re.status === 401) {
                dispatch({ type: "SET_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    decreaseQuantity(item);
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (re.status === 200) {
                dispatch({
                    type: "UPDATE_CART",
                    cartData: { ...item, quantity: item.quantity - 1 },
                });
                refetchCartTotla();
                //   toast.success(re.data.status[0].ResponseMessage);
            } else {
                toast.error(re?.resaponse?.data?.status[0]?.ResponseMessage);
            }
        } else {
            const isConfirmed = window.confirm(
                "Are you sure you want to remove product from cart?"
            );

            if (isConfirmed) {
                dispatch({ type: "SET_LOADING", status: true });
                const re = await RemoveFromCart(item.id, item.size.id);
                dispatch({ type: "SET_LOADING", status: false });
                console.log(re);
                if (re.status === 401) {
                    dispatch({ type: "SET_LOADING", status: true });
                    const refreshRe = await GenerateRefreshToken();
                    dispatch({ type: "SET_LOADING", status: false });

                    console.log(refreshRe, "REFRESH RE");

                    if (refreshRe?.data?.access_token) {
                        localStorage.setItem(
                            "refresh_token",
                            refreshRe?.data?.refresh_token
                        );
                        localStorage.setItem(
                            "token",
                            refreshRe?.data?.access_token
                        );
                        decreaseQuantity(item);
                    } else {
                        dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                        dispatch({ type: "SET_CART_ITEM", status: [] });
                        dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                        dispatch({ type: "SET_USER_DETAIL", detail: {} });
                        localStorage.clear();
                        sessionStorage.clear();
                        navigateTo("/login");
                    }
                } else if (re?.status === 200) {
                    dispatch({
                        type: "REMOVE_FROM_CART",
                        cartData: item,
                    });
                    refetchCartTotla();
                    //   toast.success(re.data.status[0].ResponseMessage);
                } else if (re?.response?.status === 401) {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.removeItem("token");
                    localStorage.removeItem("user_details");
                    localStorage.removeItem("cartItem");
                    navigateTo("/login");
                } else {
                    toast.error(
                        re?.resaponse?.data?.status[0]?.ResponseMessage
                    );
                }
            }
        }
    };
    const increaseQuantity = async (item) => {
        dispatch({ type: "SET_LOADING", status: true });
        const re = await UpdateCart({
            product_id: item.id,
            size_id: item.size.id,
            quantity: item.quantity + 1,
        });
        dispatch({ type: "SET_LOADING", status: false });
        console.log(re);
        if (re.status === 401) {
            dispatch({ type: "SET_LOADING", status: true });
            const refreshRe = await GenerateRefreshToken();
            dispatch({ type: "SET_LOADING", status: false });

            console.log(refreshRe, "REFRESH RE");

            if (refreshRe?.data?.access_token) {
                localStorage.setItem(
                    "refresh_token",
                    refreshRe?.data?.refresh_token
                );
                localStorage.setItem("token", refreshRe?.data?.access_token);
                increaseQuantity(item);
            } else {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.clear();
                sessionStorage.clear();
                navigateTo("/login");
            }
        } else if (re.status === 200) {
            dispatch({
                type: "UPDATE_CART",
                cartData: { ...item, quantity: item.quantity + 1 },
            });
            refetchCartTotla();
            //   toast.success(re.data.status[0].ResponseMessage);
        } else {
            toast.error(re.resaponse.data.status[0].ResponseMessage);
        }
    };

    useEffect(() => {
        const getDetails = async () => {
            dispatch({ type: "SET_LOADING", status: true });
            const response = await GetPopularProducts();
            dispatch({ type: "SET_LOADING", status: false });
            console.log(response);
            if (response.status === 401) {
                dispatch({ type: "SET_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    getDetails();
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (response.status === 200) {
                setPopularProducts(response?.data?.value);
                setPopularProductsImages(response?.data?.value);
            } else {
                toast.error(
                    response?.response?.data?.status[0]?.ResponseMessage
                );
            }
        };
        getDetails();
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    useEffect(() => {
        refetchCartTotla();
    }, [doDonation]);

    useEffect(() => {
        popularProducts?.forEach((prod) => {
            if (prod?.main_image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + "/uploads/" + prod?.main_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, main_image: url, loaded: true });

                    setPopularProductsImages((pre) => {
                        return pre?.map((myProd) => {
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    main_image: url,
                                    loaded_main: true,
                                };
                            } else {
                                return myProd;
                            }
                        });
                    });
                };
                xhr.send();
            }
            if (prod?.hover_image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + "/uploads/" + prod?.hover_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, hover_image: url, loaded: true });

                    setPopularProductsImages((pre) => {
                        return pre?.map((myProd) => {
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    hover_image: url,
                                    loaded_hover: true,
                                };
                            } else {
                                return myProd;
                            }
                        });
                    });
                };
                xhr.send();
            }
        });
    }, [popularProducts]);

    return (
        <div className='checkout-main'>
            {showCouponCodes && (
                <div
                    className='backdrop'
                    onClick={() => setShowCouponCodes(false)}></div>
            )}
            <h3>ADDRESS DATA AND TYPE OF DELIVERY</h3>
            <div className='checkout-container'>
                <AddressSelector
                    setSelectedAddress={setSelectedAddress}
                    selectedAddress={selectedAddress}
                />
                <div className='cart-details'>
                    <span className='cart-icon'>
                        <CartFilled />
                        <p>Your Cart</p>
                    </span>
                    <div className='product-items'>
                        {cartItem?.map((item) => (
                            <div className='item' key={"item-" + item?.id}>
                                {item?.img?.includes("blob:") ? (
                                    <img src={`${item?.img}`} alt='' />
                                ) : (
                                    <div className='loading-statistics-container'>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                    </div>
                                )}
                                <div className='name'>
                                    <p className='bold'>{item?.name}</p>
                                    <p>#{item?.id}</p>
                                </div>
                                {/* <p className="detail">{item?.color}</p> */}
                                <p className='detail'>
                                    {/* <strong>{item?.size?.title}</strong> */}
                                    <div className='quantity'>
                                        <span
                                            className='sign'
                                            onClick={() =>
                                                decreaseQuantity(item)
                                            }>
                                            -
                                        </span>
                                        <span className='number'>
                                            {item?.quantity}
                                        </span>
                                        <span
                                            className='sign'
                                            onClick={() =>
                                                increaseQuantity(item)
                                            }>
                                            +
                                        </span>
                                    </div>
                                </p>
                                <p className='detail'>
                                    <strong>{item?.size?.title}</strong>{" "}
                                </p>
                                <p className='detail price'>
                                    {(
                                        item.price - item.discount
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "INR",
                                    })}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className='total-breakdown'>
                        <p>
                            <span>Total Amount: </span>
                            {cartTotla?.cart_total?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                            })}
                        </p>
                        <p>
                            <span>Total Discount: </span>
                            {cartTotla?.total_discount?.toLocaleString(
                                "en-US",
                                {
                                    style: "currency",
                                    currency: "INR",
                                }
                            )}
                        </p>
                        <p>
                            <span>
                                Women artisans children education donation:{" "}
                            </span>
                            {cartTotla?.donation_amount?.toLocaleString(
                                "en-US",
                                {
                                    style: "currency",
                                    currency: "INR",
                                }
                            )}
                        </p>
                        <p>
                            <span>Total: </span>
                            {cartTotla?.sub_total?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                            })}
                        </p>
                    </div>
                    <div className='coins'>
                        <span
                            className='check-box'
                            onClick={() => setDoDonation((prev) => !prev)}>
                            {doDonation ? <CheckMark /> : ""}
                        </span>
                        <p>Donate 1rs for Women artisans children education.</p>
                    </div>
                    <div className='coupon'>
                        <p className='title'>Apply Coupon</p>
                        {/* <ReactSearchAutocomplete
              items={couponCodes}
              onSelect={handleOnSelect}
              showItemsOnFocus={true}
              formatResult={formatResult}
            /> */}
                        <input
                            type='text'
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            onClick={() => setShowCouponCodes(true)}
                            placeholder='Coupon Code'
                        />
                        {showCouponCodes && (
                            <div className='coupon-codes'>
                                {couponCodes
                                    ?.filter(
                                        (code) =>
                                            couponCode === "" ||
                                            code?.name
                                                ?.toLowerCase()
                                                .includes(
                                                    couponCode?.toLocaleLowerCase()
                                                )
                                    )
                                    ?.map((item) => (
                                        <div
                                            className='code'
                                            key={"code-" + item?.id}
                                            onClick={() => {
                                                setActiveCouponCode(item?.name);
                                                setCouponCode(item?.name);
                                                setShowCouponCodes(false);
                                            }}>
                                            <div className='code-details-container'>
                                                <div className='icon'>
                                                    <MasterCard />
                                                </div>
                                                <div className='details'>
                                                    <p className='bold'>
                                                        {item?.name}
                                                    </p>
                                                    <p className='desc'>
                                                        {item?.description}
                                                    </p>
                                                </div>
                                            </div>
                                            <button className='primary-btn-black'>
                                                SELECT
                                            </button>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>

                    <div className='cod'>
                        <p className='title'>Apply Coupon</p>
                        <select
                            onChange={(e) => setPaymentMethod(e.target.value)}>
                            <option value='' disabled selected>
                                {" "}
                                Payment Method
                            </option>
                            <option value='COD'> Case On delivery</option>
                            <option value='Prepaid'> Online</option>
                        </select>
                    </div>
                    {(localStorage.getItem("coins") ||
                        sessionStorage.getItem("coins")) && (
                        <div className='coins'>
                            <span
                                className='check-box'
                                onClick={() => setIsCoinUse(!isCoinUse)}>
                                {isCoinUse ? <CheckMark /> : ""}
                            </span>
                            <p>
                                Use <CoinIcon /> and get discount of{" "}
                                {localStorage.getItem("coins") ||
                                    sessionStorage.getItem("coins")}{" "}
                                rupees.
                            </p>
                        </div>
                    )}
                    <div className='buttons'>
                        <span className='total'>
                            <p>Total cost: </p>
                            <span>
                                {cartTotla?.sub_total?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "INR",
                                })}
                            </span>
                        </span>
                        {/* <button className="primary-btn-white-outline">
              CONTINUE SHOPPING
            </button> */}
                        <button
                            className='primary-btn-red'
                            onClick={() => handlePayment()}>
                            PROCEED TO PAYMENT
                        </button>
                    </div>
                </div>
            </div>
            <div className='section-2'>
                <div className='head'>
                    <h3>You May Also Like</h3>
                    <span className='text-container'>
                        <p className='desc'>Ucsccscjnnsncscnncshahuoc</p>
                        <p
                            className='shop'
                            onClick={() => navigateTo("/products")}>
                            <span>Shop All</span> <ArrowIcon />
                        </p>
                    </span>
                </div>
                <div className='products'>
                    {popularProductsImages?.map((product) => (
                        <div
                            className='product'
                            key={"product-" + product?.id}
                            onMouseEnter={() => setHoverItem(product.id)}
                            onMouseLeave={() => setHoverItem()}
                            onClick={() =>
                                navigateTo("/product-details/" + product.id)
                            }>
                            {hoverItem === product?.id ? (
                                (product?.loaded_hover && product?.loaded_main) ? (
                                    <img
                                        src={product?.hover_image}
                                        className='hover-image'
                                        alt=''
                                    />
                                ) : (
                                    <div className='loading-statistics-container hover-image'>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                    </div>
                                )
                            ) : (product?.loaded_hover && product?.loaded_main) ? (
                                <img src={product?.main_image} alt='' />
                            ) : (
                                <div className='loading-statistics-container main-img'>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                </div>
                            )}
                            <div className='content'>
                                <div className='line1'>
                                    <p className='title'>{product?.title}</p>
                                    {/* <p className='tag'>{product?.tag}</p> */}
                                </div>
                                <p className='bold'>₹{product?.price}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Checkout;
