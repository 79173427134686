import React, { useEffect } from "react";

import "./address.scss";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AddAddress, GenerateRefreshToken, UpdateAddress } from "../../apiCall";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";

const Address = ({
    setCurrentSection,
    updateAddressData,
    refetch,
    setUpdateAddressData,
}) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const navigateTo = useNavigate();
    const [, dispatch] = useStateValue()
    const onSubmit = async (data) => {
        if (updateAddressData?.id) {
            dispatch({ type: "SET_IS_LOADING", status: true });
            const re = await UpdateAddress(data);
            dispatch({ type: "SET_IS_LOADING", status: false });
            console.log(re);
            if (re.status === 401) {
                dispatch({ type: "SET_IS_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_IS_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    onSubmit(data);
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (re.status === 200) {
                setCurrentSection("selectAddress");
                refetch();
                reset();
                setUpdateAddressData({});
                toast.success(re.data.status[0].ResponseMessage);
            } else {
                toast.error(re.resaponse.data.status[0].ResponseMessage);
            }
        } else {
            dispatch({ type: "SET_IS_LOADING", status: true });
            const re = await AddAddress(data);
            dispatch({ type: "SET_IS_LOADING", status: false });
            console.log(re);
            if (re.status === 401) {
                dispatch({ type: "SET_IS_LOADING", status: true });
                const refreshRe = await GenerateRefreshToken();
                dispatch({ type: "SET_IS_LOADING", status: false });

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    onSubmit(data);
                } else {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigateTo("/login");
                }
            } else if (re.status === 201) {
                setCurrentSection("selectAddress");
                refetch();
                reset();
                setUpdateAddressData({});
                toast.success(re.data.status[0].ResponseMessage);
            } else {
                toast.error(re.resaponse.data.status[0].ResponseMessage);
            }
        }
    };
    useEffect(() => {
        if (updateAddressData?.id) {
            setValue("first_name", updateAddressData?.first_name);
            setValue("last_name", updateAddressData?.last_name);
            setValue("email", updateAddressData?.email);
            setValue("phone_number", updateAddressData?.phone_number);
            setValue("address", updateAddressData?.address);
            setValue("city", updateAddressData?.city);
            setValue("state", updateAddressData?.state);
            setValue("pin_code", updateAddressData?.pin_code);
            setValue("address_id", updateAddressData?.id);
        }
    }, [updateAddressData]);
    return (
        <form
            className='address-main'
            onSubmit={handleSubmit((data) => onSubmit(data))}>
            <div className='inputs-container'>
                <div className='input-grp'>
                    <label htmlFor='firstname'>First Name</label>
                    <input
                        type='text'
                        placeholder='First Name'
                        {...register("first_name", {
                            required: "first name is required",
                            maxLength: {
                                value: 70,
                                message:
                                    "first name length must be less then 70.",
                            },
                        })}
                    />
                    {errors?.first_name && (
                        <div className='form-error'>
                            {errors?.first_name?.message}
                        </div>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='lastname'>Last Name</label>
                    <input
                        type='text'
                        placeholder='Last Name'
                        {...register("last_name", {
                            required: "last name is required",
                            maxLength: {
                                value: 70,
                                message:
                                    "last name length must be less then 70.",
                            },
                        })}
                    />
                    {errors?.first_name && (
                        <div className='form-error'>
                            {errors?.last_name?.message}
                        </div>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='address'>Address</label>
                    <input
                        type='text'
                        placeholder='Address'
                        {...register("address", {
                            required: "address is required",
                        })}
                    />
                    {errors?.address && (
                        <div className='form-error'>
                            {errors?.address?.message}
                        </div>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='zip'>Postal Code / Zip</label>
                    <input
                        type='number'
                        placeholder='Postal Code / Zip'
                        {...register("pin_code", {
                            required: "zip cod is required",
                            pattern: {
                                value: /^\d{6}$/i,
                                message: "zip cod must be 6 digit.",
                            },
                        })}
                    />
                    {errors?.pin_code && (
                        <div className='form-error'>
                            {errors?.pin_code?.message}
                        </div>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='state'>State</label>
                    <input
                        type='text'
                        placeholder='State'
                        {...register("state", {
                            required: "state is required",
                            maxLength: {
                                value: 70,
                                message: "state length must be less then 70.",
                            },
                        })}
                    />
                    {errors?.state && (
                        <div className='form-error'>
                            {errors?.state?.message}
                        </div>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='City'>City</label>
                    <input
                        type='text'
                        placeholder='City'
                        {...register("city", {
                            required: "city is required",
                            maxLength: {
                                value: 70,
                                message: "city length must be less then 70.",
                            },
                        })}
                    />
                    {errors?.city && (
                        <div className='form-error'>
                            {errors?.city?.message}
                        </div>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='phonenumber'>Phone Number</label>
                    <input
                        type='text'
                        placeholder='Phone Number'
                        {...register("phone_number", {
                            required: "phone number is required",
                            pattern: {
                                value: /^[6-9]\d{9}$/,
                                message: "enter a valid phone number.",
                            },
                        })}
                    />
                    {errors?.phone_number && (
                        <div className='form-error'>
                            {errors?.phone_number?.message}
                        </div>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='email'>Email</label>
                    <input
                        type='text'
                        placeholder='Email'
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                message: "Enter a valid email",
                            },
                        })}
                    />
                    {errors?.email && (
                        <div className='form-error'>
                            {errors?.email?.message}
                        </div>
                    )}
                </div>
            </div>
            <div className='action'>
                <div
                    className='primary-btn-white-outline button'
                    onClick={() => {
                        setCurrentSection("selectAddress");
                        reset();
                    }}>
                    {" "}
                    Back
                </div>

                <button
                    className='primary-btn-black button'
                    onClick={() => handleSubmit()}>
                    Submit
                </button>
            </div>
        </form>
    );
};

export default Address;
