import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import Img6 from "../../Assets/images/img6.svg";
// import Img7 from "../../Assets/images/img7.svg";
// import Img10 from "../../Assets/images/img10.svg";
// import Img11 from "../../Assets/images/img11.svg";
// import Img12 from "../../Assets/images/img12.svg";
import { ReactComponent as CoinIcon } from "../../Assets/icons/coin.svg";
import { ReactComponent as CartIcon } from "../../Assets/icons/cart.svg";
import { ReactComponent as LikeIcon } from "../../Assets/icons/like.svg";
import { ReactComponent as HeartIcon } from "../../Assets/icons/heart.svg";
import { ReactComponent as ArrowIcon } from "../../Assets/icons/arrow.svg";
import { AddToFavorites } from "../../hooks/addToFavorites";
import { RemoveFromFavorites } from "../../hooks/RemoveFromFavorites";
import IsLiked from "../../hooks/isLiked";

import "./productDetails.scss";
import { useStateValue } from "../../StateProvider";
import { useQuery } from "react-query";
import {
    AddToCart,
    GetPopularProducts,
    GetProductDetails,
} from "../../apiCall";
import { CACHE_TIME, domainName } from "../../Constants";
import toast from "react-hot-toast";

//     images: [Img6, Img7, Img10, Img11, Img12],
//     title: "3d Hand Embroidered Shirley Poppy Lehenga Set",
//     price: 8904.38,
//     sizes: ["XS", "S", "L", "M"],
//     coin: 20,
//     description: [
//         "Fits true to size, take your normal size.",
//         "Lehenga is flared and has can be below.",
//         "Embellished, mid-weight fabric.",
//         "Fitted, Bustier Style.",
//         "Designed for a loose, away from the body fit.",
//     ],
//     fabric: "Lehenga: Silk Organza/ Lining: Viscose/ Bralette: Silk Organza/ Cape: Sequin Geogette/ Lining: Viscose",
//     delivery_time: "45-60 Days",
//     washing_instruction: "Dry Clean Only",
//     similar_products: [
//         {
//             id: 1,
//             img1: Img10,
//             img2: Img11,
//             price: 4000,
//             name: "Qorem Ipsum Dolor",
//             tag: "Dolor",
//         },
//         {
//             id: 2,
//             img1: Img6,
//             img2: Img7,
//             price: 4000,
//             name: "Qorem Ipsum Dolor",
//             tag: "Dolor",
//         },
//         {
//             id: 3,
//             img1: Img11,
//             img2: Img12,
//             price: 4000,
//             name: "Qorem Ipsum Dolor",
//             tag: "Dolor",
//         },
//         {
//             id: 4,
//             img1: Img7,
//             img2: Img10,
//             price: 4000,
//             name: "Qorem Ipsum Dolor",
//             tag: "Dolor",
//         },
//     ],
// };
const ProductDetails = () => {
    const navigateTo = useNavigate();

    const { id } = useParams();
    const [, dispatch] = useStateValue();
    const [selectedImage, setSelectedImage] = useState();
    const [mainImage, setMainImages] = useState()
    const [selectedSize, setSelectedSize] = useState("");
    const [hoverItem, setHoverItem] = useState();
    const [productDetails, setProductDetails] = useState({});
    const [productDetailsImage, setProductDetailsImage] = useState({});
    const [popularProducts, setPopularProducts] = useState([]);
    const [popularProductsImages, setPopularProductsImages] = useState([]);
    const [croppedImageUrl, setCroppedImageUrl] = useState('');
    const [showZoomedImage, setShowZoomedImage] = useState(false)
    const [imageCount, setImageCount] = useState(0)
    const [LoadedImageCount, setLoadedImageCount] = useState(0)

    const { isLoading: product_details_loading } = useQuery(
        ["product-details", id],
        () => GetProductDetails(id),
        {
            // staleTime: STALE_TIME,
            cacheTime: CACHE_TIME,
            onSuccess: (data) => {
                if (data.data?.status[0].Error === "False") {
                    setProductDetails({
                        ...data.data.value,
                        sub_images: [
                            data.data.value?.main_image,
                            ...data.data.value?.sub_images,
                            data.data.value?.hover_image,
                        ],
                    });
                    setProductDetailsImage({
                        ...data.data.value,
                        sub_images: [
                            data.data.value?.main_image,
                            ...data.data.value?.sub_images,
                            data.data.value?.hover_image,
                        ],
                    });
                    
                    // setMainImages(domainName + data?.data?.value?.main_image)
                    setImageCount(pre => pre+1)
                    const xhr = new XMLHttpRequest();
                    xhr.open(
                        "GET",
                        domainName + data?.data?.value?.main_image,
                        true
                    );
                    xhr.responseType = "blob";
                    xhr.onprogress = (event) => {
                        if (event.lengthComputable) {
                            const percentComplete = Math.round(
                                (event.loaded / event.total) * 100
                            );
                            // setProgress(percentComplete);
                        }
                    };
                    xhr.onload = () => {
                        const url = URL.createObjectURL(xhr.response);
                        setSelectedImage(url);
                        setMainImages(url);
                        setLoadedImageCount(pre => pre + 1)
                    };
                    xhr.send();
                }
            },
            onError: (err) => {
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );

    const showCartModel = () => {
        dispatch({
            type: "SET_CART_SHOW",
            status: true,
        });
        if (typeof window != "undefined" && window.document) {
            document.body.style.overflow = "hidden";
        }
    };

    const addtocart = async (productId, sizeId) => {
        dispatch({ type: "SET_LOADING", status: true });
        const re = await AddToCart({ product_id: productId, size_id: sizeId });
        dispatch({ type: "SET_LOADING", status: false });
        console.log(re);
        if (re.status === 201) {
            toast.success(re.data.status[0].ResponseMessage);
            return "success";
        } else {
            if (re.response.data?.detail) {
                if (re.response.data?.detail === "Unauthenticated User.") {
                    navigateTo("/login");
                } else {
                    toast.error(re.response.data?.detail);
                }
            } else {
                toast.error(re.response.data.status[0].ResponseMessage);
            }
            return "error";
        }
    };

    const handleAddToCart = async (productDetail) => {
        if(LoadedImageCount === 0 || LoadedImageCount !== imageCount){
            return;
        }
        if (selectedSize) {
            dispatch({ type: "SET_LOADING", status: true });
            const re = await addtocart(productDetail.id, selectedSize.id);
            dispatch({ type: "SET_LOADING", status: false });
            console.log(re);
            if (re === "success") {
                dispatch({
                    type: "ADD_TO_CART",
                    cartData: {
                        id: productDetailsImage.id,
                        img: mainImage || domainName + productDetailsImage?.main_image,
                        name: productDetailsImage.title,
                        size: selectedSize,
                        price: productDetailsImage.price,
                        discount: productDetailsImage.discount,
                        quantity: 1,
                    },
                });
                showCartModel();
            }
        } else {
            toast("Please select size.", {
                icon: "⚠️",
                style: {
                    padding: "16px",
                    fontSize: "18px",
                    fontWeight: 600,
                },
            });
        }
    };
    const handleBuyNow = async (productDetail) => {
        if(LoadedImageCount === 0 || LoadedImageCount !== imageCount){
            return;
        }
        if (selectedSize) {
            dispatch({ type: "SET_LOADING", status: true });
            const re = await addtocart(productDetail.id, selectedSize.id);
            dispatch({ type: "SET_LOADING", status: false });
            if (re === "success") {
                dispatch({
                    type: "ADD_TO_CART",
                    cartData: {
                        id: productDetail.id,
                        img: mainImage || domainName + productDetailsImage?.main_image,
                        name: productDetail.title,
                        size: selectedSize,
                        price: productDetail.price,
                        discount: productDetail.discount,
                        quantity: 1,
                    },
                });
                navigateTo("/checkout");
            }
        } else {
            toast("Please select size.", {
                icon: "⚠️",
                style: {
                    padding: "16px",
                    fontSize: "18px",
                    fontWeight: 600,
                },
            });
        }
    };

    useEffect(() => {
        const getDetails = async () => {
            dispatch({ type: "SET_LOADING", status: true });
            const response = await GetPopularProducts();
            dispatch({ type: "SET_LOADING", status: false });
            console.log(response);
            if (response.status === 200) {
                setPopularProducts(response?.data?.value);
                setPopularProductsImages(response?.data?.value);
            } else {
                toast.error(
                    response?.response?.data?.status[0]?.ResponseMessage
                );
            }
        };
        getDetails();
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    useEffect(() => {
        productDetails?.sub_images?.forEach((image, indx) => {
            if (image.includes(".")) {
                setImageCount(pre => pre+1)
                const xhr = new XMLHttpRequest();
                xhr.open("GET", domainName + image, true);
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, image: url, loaded: true });

                    setProductDetailsImage((pre) => {
                        return {
                            ...pre,
                            sub_images: pre?.sub_images?.map(
                                (img, img_indx) => {
                                    if (indx === img_indx) {
                                        return { image: url };
                                    } else {
                                        return img;
                                    }
                                }
                            ),
                        };
                    });
                    setLoadedImageCount(pre => pre + 1)
                };
                xhr.send();
            }
        });
    }, [productDetails?.sub_images]);

    useEffect(() => {
        if (product_details_loading) {
            dispatch({ type: "SET_LOADING", status: true });
        } else {
            dispatch({ type: "SET_LOADING", status: false });
        }
    }, [product_details_loading]);

    useEffect(() => {
        popularProducts?.forEach((prod) => {
            if (prod?.main_image?.includes(".")) {
                setImageCount(pre => pre+1)
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + "/uploads/" + prod?.main_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, main_image: url, loaded: true });

                    setPopularProductsImages((pre) => {
                        return pre?.map((myProd) => {
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    main_image: url,
                                    loaded_main: true,
                                };
                            } else {
                                return myProd;
                            }
                        });
                    });
                    setLoadedImageCount(pre => pre + 1)
                };
                xhr.send();
            }
            if (prod?.hover_image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                setImageCount(pre => pre+1)
                xhr.open(
                    "GET",
                    domainName + "/uploads/" + prod?.hover_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, hover_image: url, loaded: true });

                    setPopularProductsImages((pre) => {
                        return pre?.map((myProd) => {
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    hover_image: url,
                                    loaded_hover: true,
                                };
                            } else {
                                return myProd;
                            }
                        });
                    });
                    setLoadedImageCount(pre => pre + 1)
                };
                xhr.send();
            }
        });
    }, [popularProducts]);

    const makeCroppedImage = (e) => {
        const { offsetLeft, offsetTop, clientWidth, clientHeight } = e.target;
        const x = e.pageX - offsetLeft;
        const y = e.pageY - offsetTop;

        
        const image = e.target
        const crop = {x: x, y: y, width: image.width, height: image.height}
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = clientWidth;
        canvas.height = clientHeight;
        const ctx = canvas.getContext('2d');
        
        ctx.drawImage(
            image,
            (crop.x * scaleX / 2) + (crop.width/8),
            (crop.y * scaleY / 2) + (crop.height/8),
            crop.width * scaleX / 4,
            crop.height * scaleY / 4,
            0,
            0,
            crop.width,
            crop.height
          );
      
    
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          setCroppedImageUrl(url);
        }, 'image/jpeg');
      };

    return (
        <div className='product-details-main'>
            <div className='section-1'>
                <div className='images' onMouseLeave={() =>
                                        setSelectedImage(mainImage)
                                    }>
                    <div className='sub-images'>
                        {productDetailsImage?.sub_images?.map((img, indx) =>
                            img?.image ? (
                                <img
                                    src={img?.image}
                                    key={"img-" + indx}
                                    onClick={() =>
                                        setSelectedImage(img?.image)
                                    }
                                    onMouseEnter={() =>
                                        setSelectedImage(img?.image)
                                    }
                                    alt=''
                                    />
                                ) : (
                                    <div className='loading-statistics-container'>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                </div>
                            )
                        )}
                    </div>
                    {selectedImage ? (
                        <img
                        src={selectedImage}
                        alt=''
                        className='main-image'
                        onMouseMove={makeCroppedImage}
                        onMouseOut={() => setShowZoomedImage(false)}
                        onMouseEnter={() => setShowZoomedImage(true)}
                        />
                    ) : (
                        <div className='loading-statistics-container main-image main-image-hover'>
                            <div className='component-loading statistics-loading-cmp'></div>
                            <div className='component-loading statistics-loading-cmp'></div>
                            <div className='component-loading statistics-loading-cmp'></div>
                        </div>
                    )}
                    {showZoomedImage && <img src={croppedImageUrl} className="zoomed-img" alt="" />}
                </div>
                <div className='mobile-images'>
                    {productDetailsImage?.sub_images?.map((img, indx) =>
                        img?.image ? (
                            <img
                                src={img?.image}
                                key={"img-" + indx}
                                onClick={() =>
                                    setSelectedImage(img?.image)
                                }
                                alt=''
                            />
                        ) : indx > 0 ? (
                            <></>
                        ) : (
                            <div className='loading-statistics-container main-image main-image-hover'>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                            </div>
                        )
                    )}
                </div>
                <div className='text-container'>
                    <h3>{productDetails?.title}</h3>
                    <div className='price-container'>
                        <p className='price'>
                            {productDetails?.price?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                            })}
                        </p>
                        <p className='instruction'>
                            Tax included.{" "}
                            <span
                                onClick={() => navigateTo("/shipping-policy")}>
                                Shipping Charge
                            </span>{" "}
                            calculated at checkout.
                        </p>
                    </div>
                    <hr />
                    <div className='size-coins'>
                        <div className='sizes'>
                            <p>Sizes</p>
                            <div className='size-list'>
                                {productDetails?.size?.map((size, indx) => (
                                    <span
                                        className={`size ${
                                            selectedSize?.id === size?.id
                                                ? "selected"
                                                : ""
                                        }`}
                                        key={"size-" + size?.id}
                                        onClick={() =>
                                            setSelectedSize({
                                                id: size?.id,
                                                title: size?.variant_size,
                                            })
                                        }>
                                        {size?.variant_size}
                                    </span>
                                ))}
                            </div>
                        </div>
                        {productDetails?.coin > 0 && (
                            <p className='coins'>
                                {productDetails?.coin}
                                <CoinIcon />
                            </p>
                        )}
                    </div>
                    <div className='buttons'>
                        <button
                            className={`primary-btn-black ${(LoadedImageCount === 0 || LoadedImageCount !== imageCount) ? "disabled" : ""}`}
                            onClick={() => handleBuyNow(productDetails)}>
                            BUY NOW
                        </button>
                        <div>
                            <button
                                className={`primary-btn-white-outline ${(LoadedImageCount === 0 || LoadedImageCount !== imageCount) ? "disabled" : ""}`}
                                onClick={() => handleAddToCart(productDetails)}>
                                <CartIcon /> ADD TO CART
                            </button>
                            {IsLiked(productDetails.id) ? (
                                <button
                                className={`primary-btn-white-outline ${(LoadedImageCount === 0 || LoadedImageCount !== imageCount) ? "disabled" : ""}`}
                                    onClick={() =>{
                                        if(LoadedImageCount === 0 || LoadedImageCount !== imageCount){
                                            return;
                                        }
                                        RemoveFromFavorites(
                                            productDetails.id,
                                            dispatch,
                                            navigateTo
                                        )
                                    }}>
                                    <HeartIcon /> REMOVE FROM WISHLIST
                                </button>
                            ) : (
                                <button
                                className={`primary-btn-white-outline ${(LoadedImageCount === 0 || LoadedImageCount !== imageCount) ? "disabled" : ""}`}
                                    onClick={() =>{
                                        if(LoadedImageCount === 0 || LoadedImageCount !== imageCount){
                                            return;
                                        }
                                        AddToFavorites(
                                            {...productDetailsImage, main_image: mainImage || domainName + productDetailsImage?.main_image, hover_image: productDetailsImage?.sub_images[productDetailsImage?.sub_images?.length - 1]?.image  || 0},
                                            dispatch,
                                            navigateTo
                                        )
                                    }}>
                                    <LikeIcon /> ADD TO WISHLIST
                                </button>
                            )}
                        </div>
                    </div>
                    <hr />
                    <ul className='description'>
                        {productDetails?.featuer?.map((point, indx) => (
                            <li key={"desc-" + indx}>{point}</li>
                        ))}
                    </ul>
                    <div className='other-detail'>
                        <p className='bold'>Fabric</p>
                        <p>{productDetails?.fabric}</p>
                    </div>
                    {productDetails?.delivery_time && (
                        <div className='other-detail'>
                            <p className='bold'>Delivery Time</p>
                            <p>{productDetails?.delivery_time}</p>
                        </div>
                    )}
                    <div className='other-detail'>
                        <p className='bold'>Washing Instructions</p>
                        <p>{productDetails?.washing_instructions}</p>
                    </div>
                </div>
            </div>
            <div className='section-2'>
                <div className='head'>
                    <h3>You May Also Like</h3>
                    <span className='text-container'>
                        <p className='desc'>Ucsccscjnnsncscnncshahuoc</p>
                        <p
                            className='shop'
                            onClick={() => navigateTo("/products")}>
                            <span>Shop All</span> <ArrowIcon />
                        </p>
                    </span>
                </div>
                <div className='products'>
                    {popularProductsImages?.map((product) => (
                        <div
                            className='product'
                            key={"product-" + product?.id}
                            onMouseEnter={() => setHoverItem(product.id)}
                            onMouseLeave={() => setHoverItem()}
                            onClick={() =>
                                navigateTo("/product-details/" + product.id)
                            }>
                            {hoverItem === product?.id ? (
                                (product?.loaded_hover && product?.loaded_main) ? (
                                    <img
                                        src={product?.hover_image}
                                        className='hover-image'
                                        alt=''
                                    />
                                ) : (
                                    <div className='loading-statistics-container hover-image'>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                        <div className='component-loading statistics-loading-cmp'></div>
                                    </div>
                                )
                            ) : (product?.loaded_hover && product?.loaded_main) ? (
                                <img src={product?.main_image} alt='' />
                            ) : (
                                <div className='loading-statistics-container main-img'>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                    <div className='component-loading statistics-loading-cmp'></div>
                                </div>
                            )}
                            <div className='content'>
                                <div className='line1'>
                                    <p className='title'>{product?.title}</p>
                                    {/* <p className='tag'>{product?.tag}</p> */}
                                </div>
                                <p className='bold'>₹{product?.price}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;
