import React, { useEffect, useState } from 'react'
import Login from './Components/Login/Login'
import SignUp from './Components/Signup/SignUp'
import OtherDetails from './Components/OtherDetails/OtherDetails'
import ForgotPassword from './Components/ForgotPassword/ForgotPassword'
import OtpVerification from './Components/OTPVerification/OtpVerification'
import GoogleDetails from './Components/GoogleDetails/GoogleDetails'

const Auth = () => {
  const [currentComponent, setCurrentComponent] = useState("signup")
  const [data, setData] = useState()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <div className="auth-main">
      {window.location.pathname === "/login" && <Login />}
      {window.location.pathname === "/signup" && currentComponent === "signup" && <SignUp setCurrentComponent={setCurrentComponent} setData={setData}/>}
      {(window.location.pathname === "/signup" || window.location.pathname=== "/get-details") && currentComponent === "otp" && <OtpVerification data={data} setCurrentComponent={setCurrentComponent} />}
      {(window.location.pathname === "/signup" || window.location.pathname=== "/get-details") && currentComponent === "other" && <OtherDetails />}
      {window.location.pathname === "/get-details" && currentComponent === "signup" && <GoogleDetails setCurrentComponent={setCurrentComponent} setData={setData}/>}
      {window.location.pathname === "/forgot-password" && <ForgotPassword />}
    </div>
  )
}

export default Auth
