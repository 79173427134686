import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

import Home from "./views/Home/Home";
import "./App.scss";
import Nav from "./Components/Navbar/Nav";
import Products from "./views/Products/Products";
import Footer from "./Components/Footer/Footer";
import ProductDetails from "./views/ProductDetails/ProductDetails";
import Checkout from "./views/Checkout/Checkout";
import Wishlist from "./views/Wishlist/Wishlist";
import Contact from "./views/Contact/Contact";
import Profile from "./views/Profile/Profile";
import Faqs from "./views/Faqs/Faqs";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import ShippingPolicy from "./views/ShippingPolicy/ShippingPolicy";
import Auth from "./views/Auth/Auth";
import { GenerateRefreshToken, GetCart, GetWatchList } from "./apiCall";
import { useStateValue } from "./StateProvider";
import { useQuery } from "react-query";
import OrderSuccess from "./views/OrderStatus/OrderSuccess";
import OrderFail from "./views/OrderStatus/OrderFail";
import ProtectedRoute from "./ProtectedRoutes";
import Loader from "./Components/Loader/Loader";
import { domainName } from "./Constants";

function App() {
    const [{ isLoggedIn, isLoading, cartItem }, dispatch] = useStateValue();
    const [myCart, setMyCart] = useState([])
    const [myCartImages, setMyCartImages] = useState([])
    const [watchListProducts, setWatchListProducts] = useState([])
    const [watchListProductsImages, setWatchListProductsImages] = useState([])

    // useEffect(() => {
    const fetchData = async () => {
        dispatch({ type: "SET_LOADING", status: true });
        const re = await GetCart();
        dispatch({ type: "SET_LOADING", status: false });
        console.log(re);
        if (re.status === 401) {
            dispatch({ type: "SET_LOADING", status: true });
            const refreshRe = await GenerateRefreshToken();
            dispatch({ type: "SET_LOADING", status: false });

            console.log(refreshRe, "REFRESH RE");

            if (refreshRe?.data?.access_token) {
                localStorage.setItem(
                    "refresh_token",
                    refreshRe?.data?.refresh_token
                );
                localStorage.setItem("token", refreshRe?.data?.access_token);
                fetchData();
            } else {
                dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                dispatch({ type: "SET_CART_ITEM", status: [] });
                dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                dispatch({ type: "SET_USER_DETAIL", detail: {} });
                localStorage.clear();
                sessionStorage.clear();
            }
        } else if (re?.status === 200) {
            setMyCart(re?.data?.value)
            setMyCartImages(re?.data?.value)
        } else if (re?.response?.status === 401) {
            dispatch({ type: "SET_USER_LOGGED_IN", status: false });
            dispatch({ type: "SET_CART_ITEM", status: [] });
            dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
            dispatch({ type: "SET_USER_DETAIL", detail: {} });
            localStorage.removeItem("token");
            localStorage.removeItem("user_details");
            localStorage.removeItem("cartItem");
        } else {
            if (re?.response?.data?.detail) {
                // toast.error(re?.response?.data?.detail);
            } else {
                toast.error(re?.response?.data?.status[0].ResponseMessage);
            }
        }
    };

    const { isLoading: product_details_loading } = useQuery(
        ["cart-details"],
        () => fetchData()
    );

    useEffect(() => {
        myCart?.forEach((prod) => {
            if (prod?.img?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + prod?.img,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    console.log("CAET", url);
                    // displayProducts.push({ ...prod, img: url, loaded: true });

                    setMyCartImages((pre) => {
                        console.log(pre, "MY CAET IMG")
                        const updatedState = pre?.map((myProd) => {
                            console.log(myProd?.id, prod.id, "MY CART IMG")
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    img: url,
                                };
                            } else {
                                return myProd;
                            }
                        });
                        dispatch({ type: "SET_CART_ITEM", data: updatedState });
                        return updatedState
                    });
                    console.log(myCartImages, "MY CART IMAGES")
                };
                xhr.send();
            }
        });
    }, [myCart]);

    useEffect(() => {
        watchListProducts?.forEach((prod) => {
            if (prod?.main_image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + prod?.main_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, main_image: url, loaded: true });

                    setWatchListProductsImages((pre) => {
                        const updatedProducts = pre?.map((myProd) => {
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    main_image: url,
                                    loaded_main: true,
                                };
                            } else {
                                return myProd;
                            }
                        });
                        dispatch({
                            type: "SET_LIKED_PRODUCT",
                            data: updatedProducts,
                        });
                        return updatedProducts;
                    });
                };
                xhr.send();
            }
            if (prod?.hover_image?.includes(".")) {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    "GET",
                    domainName + prod?.hover_image,
                    true
                );
                xhr.responseType = "blob";
                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // setProgress(percentComplete);
                    }
                };
                xhr.onload = () => {
                    const url = URL.createObjectURL(xhr.response);
                    // displayProducts.push({ ...prod, hover_image: url, loaded: true });

                    setWatchListProductsImages((pre) => {
                        const updatedProducts = pre?.map((myProd) => {
                            if (myProd?.id === prod?.id) {
                                return {
                                    ...myProd,
                                    hover_image: url,
                                    loaded_hover: true,
                                };
                            } else {
                                return myProd;
                            }
                        });
                        dispatch({
                            type: "SET_LIKED_PRODUCT",
                            data: updatedProducts,
                        });
                        return updatedProducts;
                    });
                };
                xhr.send();
            }
        });
    }, [watchListProducts]);

    useEffect(() => {
        const dataFetch = async () => {
            if (isLoggedIn) {
                dispatch({ type: "SET_LOADING", status: true });
                const watchList = await GetWatchList();
                dispatch({ type: "SET_LOADING", status: false });
                if (watchList.status === 401) {
                    dispatch({ type: "SET_LOADING", status: true });
                    const refreshRe = await GenerateRefreshToken();
                    dispatch({ type: "SET_LOADING", status: false });

                    console.log(refreshRe, "REFRESH RE");

                    if (refreshRe?.data?.access_token) {
                        localStorage.setItem(
                            "refresh_token",
                            refreshRe?.data?.refresh_token
                        );
                        localStorage.setItem(
                            "token",
                            refreshRe?.data?.access_token
                        );
                        dataFetch();
                    } else {
                        dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                        dispatch({ type: "SET_CART_ITEM", status: [] });
                        dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                        dispatch({ type: "SET_USER_DETAIL", detail: {} });
                        localStorage.clear();
                        sessionStorage.clear();
                    }
                } else if (watchList?.status === 200) {
                    // dispatch({
                    //     type: "SET_LIKED_PRODUCT",
                    //     data: watchList?.data?.value || [],
                    // });
                    setWatchListProductsImages(watchList?.data?.value)
                    setWatchListProducts(watchList?.data?.value)
                    dispatch({ type: "SET_LOADING", status: true });
                    const cart = await GetCart();
                    dispatch({ type: "SET_LOADING", status: false });
                    if (cart?.status === 200) {
                        dispatch({
                            type: "SET_CART_ITEM",
                            data: cart?.data?.value || [],
                        });
                    }
                } else if (watchList?.response?.status === 401) {
                    dispatch({ type: "SET_USER_LOGGED_IN", status: false });
                    dispatch({ type: "SET_CART_ITEM", status: [] });
                    dispatch({ type: "SET_LIKED_PRODUCT", status: [] });
                    dispatch({ type: "SET_USER_DETAIL", detail: {} });
                    localStorage.removeItem("token");
                    localStorage.removeItem("user_details");
                    localStorage.removeItem("cartItem");
                }
            }
        };
        dataFetch();
    }, [dispatch, isLoggedIn]);
    return (
        <div className='App'>
            {isLoading && <Loader />}
            <BrowserRouter>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Home />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/products'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Products />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/products/:category_id'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Products />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/product-details/:id'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <ProductDetails />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/checkout'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Checkout />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/wishlist'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Wishlist />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/contact-us'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Contact />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/profile'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Profile />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/faqs'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Faqs />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/privacy-policy'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <PrivacyPolicy />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/shipping-policy'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <ShippingPolicy />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/login'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav is_login={true} />
                                    <Auth />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/signup'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav is_login={true} />
                                    <Auth />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/get-details'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav />
                                    <Auth />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/forgot-password'
                        element={
                            <>
                                <ProtectedRoute>
                                    <Nav is_login={true} />
                                    <Auth />
                                    <Footer />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/order-success'
                        element={
                            <>
                                <ProtectedRoute>
                                    <OrderSuccess />
                                </ProtectedRoute>
                            </>
                        }
                    />
                    <Route
                        path='/order-fail'
                        element={
                            <>
                                <ProtectedRoute>
                                    <OrderFail />
                                </ProtectedRoute>
                            </>
                        }
                    />
                </Routes>
            </BrowserRouter>
            {window.screen.width < 750 ? (
                <Toaster
                    position='bottom-center'
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    theme='light'
                />
            ) : (
                // <ToastContainer

                // />
                <Toaster
                    position='top-center'
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    theme='light'
                />
                // <ToastContainer
                //     position='top-right'
                //     autoClose={2000}
                //     hideProgressBar={false}
                //     newestOnTop={false}
                //     closeOnClick
                //     rtl={false}
                //     draggable
                //     theme='light'
                // />
            )}
        </div>
    );
}

export default App;
